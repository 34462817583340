@use '../../basics/globals' as *;

#interface-dashboard-users {

    .flex-wrapper {

        .flex-item  {
            @include flex(1 0 210px);
        }
    }
}
