@use '../../basics/globals' as *;

#clients-overlay-translate-language {
    width: 350px;

    .wrapper-translate {

        form {
            margin-top: 20px;
        }
    }

    .wrapper-success {
        display: none;
    }

    &.show-success {
        width: 500px;

        .wrapper-translate {
            display: none;
        }

        .wrapper-success {
            display: block;
        }
    }
}
