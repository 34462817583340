@use '../../basics/globals' as *;

.mixin-tooltip {

    .text {
        max-width: 300px;
        color: $color-grey-white !important;
        font-weight: 500 !important;
        text-align: center !important;
        white-space: initial !important;
        word-break: normal !important;

        span {
            color: inherit;
            font: inherit;
            text-align: inherit;
            white-space: inherit;
            word-break: inherit;

            &.headline {
                display: block;
                margin-bottom: 5px;
                font-size: $font-size-12px;
                text-transform: uppercase !important;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .text-bg {
        border-color: $color-grey-white;
        background: $color-blue-dark;
        @include opacity(1);
        @include box-shadow(5px, 5px, 10px, 0, rgba(0, 0, 0, 0.2));

        .arrow-mask {

            .arrow-position {

                .arrow {
                    border-color: $color-grey-white;
                    background: $color-blue-dark;
                    @include box-shadow(5px, 5px, 10px, 0, rgba(0, 0, 0, 0.2));
                }
            }
        }
    }
}
