@use '../../basics/globals' as *;

#pricing-overlay-plan-helper {
    width: 600px;

    .row {

        p {
            color: $color-blue-grey;
            font-size: $font-size-16px;
            line-height: 1.4;
        }

        .mixin-slider-range {
            padding-bottom: 20px;
            border-bottom: 1px solid $color-blue-logo-2-opacity-010p;

            .header,
            .irs--flat {
                margin: 0 20px;
            }
        }
    }
}
