@use '../../basics/globals' as *;

#interface-dashboard {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    overflow-x: hidden;
    @include flex(1 1 0);

    section[id^=interface-dashboard] {
        display: none;
        position: relative;
        z-index: 1;
        padding: 20px;
        @include flex(1 1 auto);

        > header {
            display: flex;
            margin: -20px -20px 20px -20px;
            padding: 0 20px;
            font-size: 0;
            background: $color-blue-logo-2;

            > h2 {
                color: $color-grey-light;
                font-size: $font-size-16px;
                font-weight: 600;
                line-height: 50px;
                @include flex(1 0 auto);
                @include font-family('Open Sans');

                .text {
                    margin-right: 15px;
                    color: inherit;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;

                    &[data-dashboard-id] {
                        text-decoration: underline;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .icon {
                    margin-right: 15px;
                    color: inherit;
                    font-size: 14px;
                    line-height: inherit;
                }
            }

            > ul {
                @include flex(0 0 auto);

                li {
                    margin-right: -10px;
                    padding: 0 10px;
                    font-size: 0;

                    .icon {
                        color: $color-grey-light;
                        font-size: 20px;
                        line-height: 50px;
                    }
                }
            }
        }

        > .flex-wrapper {
            display: flex;
            flex-wrap: wrap;
            max-height: calc(100vh - 180px);
            overflow-x: hidden;
            overflow-y: auto;
            @include smooth-scroll();
            @include gap(20px);

            > .flex-item {
                display: flex;
                position: relative;
                height: 50px;
                font-size: 0;
                justify-content: space-between;
                overflow: hidden;
                cursor: pointer;
                background: $color-grey-white;
                border: 1px solid $color-grey-dark;

                .text {
                    font-size: $font-size-14px;
                    line-height: 18px;

                    &.number {
                        margin: -3px 10px -3px 0;
                        padding: 3px 7px;
                        color: $color-grey-light;
                        font-weight: 600;
                        background: $color-blue-logo-2;
                        @include flex(0 0 auto);
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                    }

                    &.name {
                        font-weight: 600;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @include flex(1 1 auto);
                    }

                    &.border {
                        margin: -4px 0 -4px 10px;
                        padding: 3px 7px;
                        color: $color-blue-logo-2;
                        font-weight: 600;
                        border: 1px solid $color-blue-logo-2;
                        @include flex(0 0 auto);
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                        .icon {
                            margin-left: 10px;
                            color: $color-blue-logo-2;
                        }
                    }

                    &.placeholder {
                        font-size: 0;
                        pointer-events: none;
                        @include flex(1 1 auto);
                    }

                    .icon {
                        margin-left: 15px;
                        font-size: 16px;

                        &.icon-checkmark {
                            color: $color-green-middle;
                        }
                    }
                }

                &.highlight-blue {
                    border: 1px solid $color-blue-logo-2;

                    .text {

                        .icon {
                            color: $color-blue-logo-2;
                        }

                        &.number {
                            background: $color-blue-logo-2;
                        }

                        &.border {
                            color: $color-blue-logo-2;
                            border: 1px solid $color-blue-logo-2;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background: $color-blue-logo-2;
                    }
                }

                &.highlight-green {
                    border: 1px solid $color-green-middle;

                    .text {

                        .icon {
                            color: $color-green-middle;
                        }

                        &.number {
                            background: $color-green-middle;
                        }

                        &.border {
                            color: $color-green-middle;
                            border: 1px solid $color-green-middle;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background: $color-green-middle;
                    }
                }

                &.highlight-purple {
                    border: 1px solid $color-purple-middle;

                    .text {

                        .icon {
                            color: $color-purple-middle;
                        }

                        &.number {
                            background: $color-purple-middle;
                        }

                        &.border {
                            color: $color-purple-middle;
                            border: 1px solid $color-purple-middle;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background: $color-purple-middle;
                    }
                }

                &.highlight-orange {
                    border: 1px solid $color-orange-middle;

                    .text {

                        .icon {
                            color: $color-orange-middle;
                        }

                        &.number {
                            background: $color-orange-middle;
                        }

                        &.border {
                            color: $color-orange-middle;
                            border: 1px solid $color-orange-middle;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background: $color-orange-middle;
                    }
                }

                &.highlight-red {
                    border: 1px solid $color-red-error;

                    .text {

                        .icon {
                            color: $color-red-error;
                        }

                        &.number {
                            background: $color-red-error;
                        }

                        &.border {
                            color: $color-red-error;
                            border: 1px solid $color-red-error;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 3px;
                        background: $color-red-error;
                    }
                }

                &.grey-out {
                    border: 1px solid $color-grey-bright;
                    @include opacity(0.5);

                    .text {

                        &.number {
                            background: $color-grey-bright;
                        }

                        &.border {
                            color: $color-grey-bright;
                            border: 1px solid $color-grey-bright;

                            .icon {
                                color: $color-grey-bright;
                            }
                        }
                    }

                    .icon {
                        color: $color-grey-bright !important;
                    }

                    &:before {
                        background: $color-grey-bright;
                    }
                }

                &.placeholder {
                    pointer-events: none;
                    @include opacity(0);
                }
            }
        }

        .warning-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(100vh - 180px);
            text-align: center;

            .icon {
                margin-bottom: 30px;
                font-size: 60px !important;
            }

            p {
                margin: 0 50px 20px 50px;
                font-weight: 400;
            }
        }

        &.show {
            display: block;
        }
    }
}

@media screen and (max-width: 400px) {

    #interface-dashboard {

        section[id^=interface-dashboard] {
            min-width: calc(100vw - 50px);

            > .flex-wrapper {

                > .flex-item {

                    &.placeholder {
                        display: none;
                    }
                }
            }
        }
    }
}
