@use '../basics/globals' as *;

.mixin-query {

    &.mixin-query-pagination {
        padding: 20px 0;
        text-align: center;

        .btn {
            cursor: pointer;

            &.disabled {
                cursor: default;
                background: $color-grey-middle;
            }
        }

        .select2 {
            display: inline-block !important;
            width: 130px !important;
            margin: 0 10px;
        }
    }

    &.mixin-query-select {

        > .btn {
            float: right;
            margin-left: 10px;
            cursor: pointer;
        }

        .fluid-wrapper {
            height: 35px;

            .inner-wrapper {
                height: 100%;
            }
        }
    }

    &.mixin-query-condition {
        float: right;

        .select2 {
            width: 180px !important;
        }
    }

}
