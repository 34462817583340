@use '../../basics/globals' as *;

.mixin-illustration {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: relative;
    }
}
