@use '../../basics/globals' as *;

pre[class*="language-"] {
    margin: 0;
    padding: 10px 15px;
}

code[class*="language-"] {
    padding: 0 !important;
    white-space: pre-wrap;
    word-break: break-word;
    background: $transparent !important;

    * {
        font-family: Courier;
        font-size: $font-size-16px;
    }
}

.token.boolean,
.token.constant,
.token.deleted,
.token.number,
.token.property,
.token.symbol,
.token.tag {
    color: $color-purple-middle;
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: $color-green-middle;
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: $color-blue-logo-2;
}

.token.punctuation {
    color: $color-grey-dark;
}
