@use '../../basics/globals' as *;

#sidebar {
    position: fixed;
    z-index: 200000;
    top: 0;
    bottom: 0;
    right: -500px;
    width: 500px;
    max-width: 100%;
    @include user-select(none);
    @include transition(right, 0.4s, $ease-in-out-cubic, 0s);

    .badges {
        display: flex;
        flex-direction: column;
        align-items: end;
        position: absolute;
        top: 50%;
        right: calc(100% - 60px);
        z-index: 1;
        @include gap(2px);
        @include transform(translate(0, -50%));
        @include transition(all, 0.4s, $ease-in-out-cubic, 3s);

        .badge {
            display: flex;
            position: relative;
            height: 48px;
            cursor: pointer;
            background: $color-blue-dark;

            a {
                display: flex;
                width: 100%;
                height: 100%;
            }

            .unread {
                position: absolute;
                top: -10px;
                left: -10px;
                z-index: 1;
                width: 26px;
                height: 26px;
                color: $color-grey-white;
                font-size: $font-size-14px;
                font-weight: 400;
                line-height: 27px;
                text-align: center;
                background: $color-purple-middle;
                @include border-radius(50%, 50%, 50%, 50%);
                @include font-family('Museo Slab');
                @include transition(background, 0.8s, $ease-in-out-cubic, 0s);
            }

            .icon {
                color: $color-grey-white;
                width: 48px;
                height: 100%;
                font-size: 25px;
                line-height: 48px;
                background: $color-blue-dark;
                @include flex(0 0 48px);
                @include transition(background, 0.8s, $ease-in-out-cubic, 0s);
            }

            .info {
                max-width: 0;
                height: 48px;
                font-size: 0;
                overflow: hidden;
                @include transition(all, 0.8s, ease, 0s);

                .text {
                    padding: 0 15px;
                    color: $color-grey-white;
                    font-size: $font-size-16px;
                    line-height: 48px;
                    white-space: nowrap;
                    @include font-family('Exo 2');
                }
            }

            &:hover:not(.no-css-hover) {

                .unread {
                    background: $color-blue-dark;
                    @include transition(background, 0.3s, ease, 0s);
                }

                .icon {
                    background: $color-blue-logo-1;
                    @include transition(background, 0.3s, ease, 0s);
                }

                .info {
                    max-width: 300px;
                    @include transition(all, 0.8s, $ease-in-out-cubic, 0s);
                }
            }
        }
    }

    .assistant {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        background: $color-grey-white;
        @include box-shadow(0, 0, 20px, 0, rgba(0, 0, 0, 0));
        @include transition(box-shadow, 0.4s, ease-out, 0s);

        > header {
            display: flex;
            width: 100%;
            padding: 13px 20px;
            background: $color-blue-logo-2;
            @include flex(0 0 auto);

            > .back,
            > .back-placeholder,
            > .close {
                display: flex;
                height: 40px;
                align-items: center;
                @include flex(0 0 40px);

                .icon {
                    color: $color-grey-white;
                    font-size: 16px;
                }

                &.back {
                    cursor: pointer;
                }

                &.close {
                    justify-content: right;
                    cursor: pointer;

                    .icon {

                        &:last-child {
                            margin-left: -5px;
                        }
                    }
                }
            }

            > .title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 40px;
                font-size: 0;
                text-align: center;
                line-height: 1em;
                @include flex(1 1 auto);

                .headline {
                    display: block;
                    color: $color-grey-white;
                    font-weight: 400;
                    line-height: 22px;
                    @include font-family('Exo 2');
                }

                .subline {
                    color: $color-blue-bright;
                    font-size: $font-size-12px;
                    font-weight: 400;
                    line-height: 20px;
                    @include font-family('Exo 2');

                    span {
                        color: inherit;
                        font: inherit;
                        line-height: inherit;
                    }
                }

                [data-overlay-url] {

                    &:hover:not(.no-css-hover) {
                        text-decoration: underline;
                    }
                }
            }
        }

        > .translator {
            padding: 20px;
            border-top: 1px solid $color-blue-logo-2-opacity-010p;
        }

        > .messenger {
            width: 100%;
            padding: 20px;
            border-top: 1px solid $color-blue-logo-2-opacity-010p;
            @include flex(0 0 auto);

            textarea {
                height: 100px;
            }
        }

        > .conversations {
            padding: 10px 20px;
            overflow-x: hidden;
            overflow-x: auto;
            @include flex(1 1 auto);

            .conversation {
                display: flex;
                position: relative;
                align-items: center;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $color-blue-logo-2-opacity-010p;
                cursor: pointer;

                .teaser {
                    display: flex;
                    position: relative;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                    @include flex-wrap(wrap);
                    @include gap(0 20px);
                    @include flex(1 1 auto);

                    .line {
                        display: flex;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @include flex(1 0 100%);

                        .reference {
                            color: $color-grey-bright;
                            font-size: $font-size-14px;
                            font-weight: 400;
                            @include user-select(none);
                            @include flex(0 0 auto);

                            &:nth-child(2) {
                                padding: 0 10px;
                                font-weight: 300;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                @include flex(0 1 auto);
                            }
                        }

                        .date {
                            color: $color-grey-bright;
                            font-size: $font-size-14px;
                            text-align: right;
                            @include user-select(none);
                            @include flex(1 0 auto);
                        }

                        .title {
                            color: $color-blue-logo-2;
                            font-weight: 600;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            @include user-select(none);
                            @include flex(1 1);

                            &.customer-service {
                                color: $color-blue-logo-1;
                            }
                        }

                        .text {
                            font-size: $font-size-16px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            @include user-select(none);
                            @include flex(1 0 100%);
                        }

                        .link {
                            color: $color-grey-bright;
                            font-size: $font-size-12px;
                            font-weight: 300;
                            text-decoration: underline;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            @include flex(0 1 auto);
                        }
                    }
                }

                .info {
                    position: relative;
                    text-align: right;
                    @include flex(0 0 40px);

                    .icon {
                        color: $color-blue-logo-2;
                    }

                    .unread {
                        display: inline-block;
                        width: 26px;
                        height: 26px;
                        color: $color-grey-white;
                        font-size: $font-size-14px;
                        font-weight: 400;
                        line-height: 27px;
                        text-align: center;
                        background: $color-purple-middle;
                        @include border-radius(50%, 50%, 50%, 50%);
                        @include font-family('Museo Slab');
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover:not(.no-css-hover) {

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        top: -11px;
                        bottom: -1px;
                        left: -20px;
                        right: -20px;
                        background: $color-blue-logo-2-opacity-005p;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        z-index: 0;
                        top: -11px;
                        bottom: -1px;
                        left: -20px;
                        width: 5px;
                        background: $color-purple-middle;
                    }
                }
            }
        }

        > .conversation {
            width: calc(100% - 20px);
            margin-left: 20px;
            overflow-x: hidden;
            overflow-x: auto;
            @include flex(1 1 auto);

            > .message {
                display: block;

                > .date {
                    display: block;
                    margin: 15px 20px 15px 0;
                    color: $color-grey-bright;
                    font-size: $font-size-12px;
                    font-weight: 400;
                    text-align: center;
                }

                > .text {
                    display: inline-block;
                    padding: 10px;
                    font-size: $font-size-14px;
                    font-weight: 400;
                    line-height: 20px;
                    hyphens: auto;
                    @include border-radius($border-radius-10px, $border-radius-10px, $border-radius-10px, $border-radius-10px);
                }

                > .info {
                    display: flex;
                    margin: 3px 25px 0 5px;

                    .time {
                        position: relative;
                        top: 2px;
                        padding: 2px 0 15px 0;
                        color: $color-grey-bright;
                        font-size: $font-size-12px;
                        font-weight: 400;
                        line-height: 1em;
                    }

                    .status {
                        display: flex;
                        margin-left: 10px;
                        padding: 2px 0 15px 0;
                        line-height: 1em;

                        .icon {
                            top: 1px;
                            color: $color-blue-logo-1;
                            font-size: 13px;
                        }
                    }
                }

                &.other {

                    > .text {
                        margin-right: 60px;
                        background: $color-grey-light;
                    }
                }

                &.own {
                    text-align: right;

                    > .text {
                        margin: 0 20px 0 60px;
                        color: $color-grey-white;
                        text-align: left;
                        background: $color-blue-logo-1;
                    }

                    > .info {
                        justify-content: right;
                    }
                }
            }

            > .info {
                display: flex;
                flex-direction: column;
                margin: 40px 20px 20px 0;
                @include gap(10px 0);

                .text {
                    display: block;
                    color: $color-grey-bright;
                    font-size: $font-size-12px;
                    font-weight: 400;
                    text-align: center;

                    &.link {
                        color: $color-blue-logo-2;
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }

        > .no-conversations,
        > .no-messages {
            display: flex;
            align-items: center;
            padding: 20px;
            overflow-x: hidden;
            overflow-x: auto;
            @include flex(1 1 auto);

            p {
                color: $color-grey-bright;
                font-size: $font-size-14px;
                font-weight: 400;
                text-align: center;
                @include flex(1 1 auto);
            }
        }

        > .loading {
            position: relative;
            @include flex(1 0 auto);

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 200px;
                height: 200px;
                background-image: url('../images/assets/app/layout/loading.svg?#{$timestamp}');
                background-size: 100%;
                @include transform(translate(-50%, -50%));
            }
        }

        > .error {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 20px;
            @include flex(1 0 auto);

            .icon {
                margin-bottom: 30px;
                font-size: 60px;
            }

            h3 {
                margin: 0 50px 20px 50px;
                font-size: $font-size-30px !important;
                font-weight: 600;
                @include font-family('Open Sans');
            }
        }

        > .background {
            content: '';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            background: #000;
            pointer-events: none;
            @include opacity(0);
            @include transition(opacity, 0.4s, ease-out, 0s);
        }
    }

    &.show {
        right: 0;

        .badges {
            @include transition(right, 0s, linear, 0s);
        }

        .assistant {
            @include box-shadow(0, 0, 20px, 0, rgba(0, 0, 0, 0.5));

            .background {
                pointer-events: auto;
                @include opacity(0.5);
            }
        }
    }

    &.show-badges {

        .badges {
            right: calc(500px + 2px);
        }
    }
}

@media screen and (max-width: 1000px) {

    #sidebar {
        position: absolute;

        .badges {
            position: fixed;
            bottom: -50px;
            top: auto;
            left: 50%;
            right: auto !important;
            flex-direction: row;
            @include transform(translate(-50%, 0));

            .badge {
                background: $color-grey-white !important;
                @include flex(0 0 48px);

                .unread {
                    background: $color-purple-middle !important;
                }

                .icon {
                    color: $color-blue-dark;
                    background: $color-grey-white !important;
                }

                .info {
                    width: 0;
                }
            }

            &:before {
                content: '';
                position: absolute;
                bottom: -2px;
                left: -50vw;
                right: -50vw;
                z-index: -1;
                height: 52px;
                background: $color-blue-dark;
                @include box-shadow(0, 3px, 10px, 0, rgba(0, 0, 0, 0.1));
            }
        }

        .assistant {
            height: calc(100% - 52px);
        }

        &.show,
        &.showing-sidebar,
        &.closing-sidebar {
            position: fixed;
        }
    }

    body {

        &.body-loaded {

            #sidebar {

                .badges {
                    bottom: 2px;
                }
            }
        }
    }
}
