@use '../../basics/globals' as *;

[id^=interface-dashboard-areas-] {

    .flex-wrapper {

        .flex-item  {
            @include flex(1 0 180px);
        }
    }
}
