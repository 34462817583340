@use '../../basics/globals' as *;

.mixin-form-label {
    display: flex;
    margin-bottom: 3px;
    @include flex-wrap(wrap);
    @include gap(0 8px);

    .text {
        font-size: $font-size-14px;
        font-weight: 600;
        line-height: 21px;
        @include flex(0 0 auto);

        &:first-letter {
            text-transform: capitalize;
        }
    }

    .info {
        position: relative;
        top: 3px;
        display: inline-block;
        padding-bottom: 3px;
        color: $color-blue-bright;
        font-size: $font-size-12px;
        @include flex(1 1 auto);
    }

    &.line-break {

        .info {
            display: block;
            @include flex(1 1 100%);
        }

        &:after {
            content: none;
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-form-label {

        .text {
            font-weight: 700;
        }

        .info {
            font-weight: 500;
        }
    }
}
