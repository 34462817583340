@use '../../basics/globals' as *;

.mixin-background-lines {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .center-wide-ctn {
        position: relative;
        height: 100%;

        .background-lines-solid {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .left,
            .center,
            .right {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 1px;
                background: $color-blue-logo-2-opacity-010p;
            }

            .left {
                left: -20px;
            }

            .center {
                left: 50%;
                width: 1px;
                background: $color-blue-logo-2-opacity-010p;
            }

            .right {
                right: -20px;
            }
        }

        .background-lines-dotted {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .left,
            .right {
                position: absolute;
                top: 0;
                bottom: 0;
                width: 1px;
                background: linear-gradient(0deg, $color-blue-logo-2-opacity-010p, $color-blue-logo-2-opacity-010p 50%, $transparent 0, $transparent);
                background-size: 1px 8px;
            }

            .left {
                left: 25%;
            }

            .right {
                right: 25%;
            }
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-background-lines {

        .center-wide-ctn {

            .background-lines-solid {

                .left {
                    left: -10px;
                }

                .right {
                    right: -10px;
                }
            }

            .background-lines-dotted {

                .left {
                    @include transform(translate(-5px, 0));
                }

                .right {
                    @include transform(translate(5px, 0));
                }
            }
        }
    }
}
