@use '../../basics/globals' as *;

#interface-dashboard-areas {

    .flex-wrapper {

        .flex-item  {
            @include flex(1 0 350px);
        }
    }
}

@media screen and (max-width: 500px) {

    #interface-dashboard-areas {

        .flex-wrapper {

            .flex-item  {
                @include flex(1 0 210px);
            }
        }
    }
}
