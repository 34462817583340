@use '../../basics/globals' as *;

#interface-login-authentication {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;

    .tile-wrapper {
        width: 400px;
        max-width: 100%;
        padding: 20px !important;

        form {
            width: 100%;

            .row {

                label {
                    font-size: $font-size-16px !important;

                    span {
                        margin: 10px 0;
                    }
                }

                .logo {
                    float: right;
                    margin: 10px 0 20px 0;
                    padding: 0 10px 0 0;
                    font-size: 0;

                    img {
                        height: 14px;
                    }
                }

                .mixin-form-number-pad {
                    margin: 20px 0;
                }
            }
        }
    }
}
