@use 'globals' as *;

@font-face {
    font-family: 'icomoon';
    src: url('../icons/app/icomoon.eot?#{$timestamp}');
    src: url('../icons/app/icomoon.eot?#{$timestamp}#iefix') format('embedded-opentype'),
         url('../icons/app/icomoon.ttf?#{$timestamp}') format('truetype'),
         url('../icons/app/icomoon.woff?#{$timestamp}') format('woff'),
         url('../icons/app/icomoon.svg?#{$timestamp}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-logo-icon:before {
  content: '\e90d';
}

.icon-logo-icon-font:before {
  content: '\e912';
}

.icon-arrow-long-right:before {
  content: '\e90f';
}

.icon-arrow-left:before {
  content: '\e916';
}

.icon-arrow-right:before {
  content: '\e91b';
}

.icon-arrows-left:before {
  content: '\e929';
}

.icon-arrows-right:before {
  content: '\e92a';
}

.icon-arrow-up:before {
  content: '\e922';
}

.icon-arrow-down:before {
  content: '\e923';
}

.icon-app-icon-facebook:before {
  content: '\e905';
}

.icon-app-icon-google:before {
  content: '\e906';
}

.icon-app-icon-tripadvisor:before {
  content: '\e907';
}

.icon-app-icon-share:before {
  content: '\e93d';
}

.icon-change:before {
  content: '\e918';
}

.icon-enlarge:before {
  content: '\e919';
}

.icon-cloud:before {
  content: '\e917';
}

.icon-link:before {
  content: '\e91a';
}

.icon-lock:before {
  content: '\e92d';
}

.icon-shield:before {
  content: '\e92f';
}

.icon-off:before {
  content: '\e928';
}

.icon-copy:before {
  content: '\e935';
}

.icon-trash:before {
  content: '\e915';
}

.icon-edit:before {
  content: '\e911';
}

.icon-warning:before {
  content: '\e910';
}

.icon-checkmark:before {
  content: '\e90a';
}

.icon-x:before {
  content: '\e914';
}

.icon-pending:before {
  content: '\e927';
}

.icon-location:before {
  content: '\e92c';
}

.icon-search:before {
  content: '\e908';
}

.icon-filter:before {
  content: '\e909';
}

.icon-percentage:before {
  content: '\e902';
}

.icon-eye:before {
  content: '\e913';
}

.icon-eye-closed:before {
  content: '\e938';
}

.icon-star-filled:before {
  content: '\e904';
}

.icon-star:before {
  content: '\e903';
}

.icon-heart-filled:before {
  content: '\e924';
}

.icon-heart:before {
  content: '\e925';
}

.icon-refresh:before {
  content: '\e926';
}

.icon-account:before {
  content: '\e90b';
}

.icon-smiley:before {
  content: '\e940';
}

.icon-infinite:before {
  content: '\e941';
}

.icon-credit-card:before {
  content: '\e90c';
}

.icon-cutlery:before {
  content: '\e901';
}

.icon-settings:before {
  content: '\e91c';
}

.icon-order:before {
  content: '\e91d';
}

.icon-billing:before {
  content: '\e921';
}

.icon-statistics:before {
  content: '\e92e';
}

.icon-table:before {
  content: '\e92b';
}

.icon-fingerprint:before {
  content: '\e930';
}

.icon-delete:before {
  content: '\e931';
}

.icon-translations:before {
  content: '\e900';
}

.icon-bug:before {
  content: '\e90e';
}

.icon-drink:before {
  content: '\e91f';
}

.icon-rocket:before {
  content: '\e920';
}

.icon-send:before {
  content: '\e936';
}

.icon-play:before {
  content: '\e93e';
}

.icon-envelope:before {
  content: '\e937';
}

.icon-phone:before {
  content: '\e93f';
}

.icon-bot:before {
  content: '\e91e';
}

.icon-quotes:before {
  content: '\e933';
}

.icon-excavator:before {
  content: '\e932';
}

.icon-plan:before {
  content: '\e934';
}

.icon-logo-icon-facebook:before {
  content: '\e939';
}

.icon-logo-icon-linkedin:before {
  content: '\e93a';
}

.icon-logo-icon-x:before {
  content: '\e93b';
}

.icon-logo-icon-xing:before {
  content: '\e93c';
}
