@use '../../basics/globals' as *;

#interface-navigation {
    display: flex;
    position: relative;
    background: $color-blue-middle;
    @include flex(0 0 0);

    nav {

        ul {
            margin-top: 50px;
            font-size: 0;
            overflow: hidden;
            @include transition(width, $duration-300ms, ease-out, 0s);

            li {
                position: relative;
                font-size: 0;
                white-space: nowrap;
                cursor: pointer;
                @include opacity(0);
                @include transition(opacity, $duration-300ms, ease-out, 0s);

                .icon {
                    top: 9px;
                    width: 20px;
                    margin: 0 15px;
                    color: $color-grey-light;
                    font-size: 20px;
                }

                .text {
                    display: inline-block;
                    padding: 10px 20px 10px 0;
                    color: $color-grey-light;
                    font-size: $font-size-14px;
                    font-weight: 600;
                    line-height: 20px;
                    @include transition(opacity, $duration-300ms, ease-out, $duration-300ms);
                }

                &.show {
                    @include opacity(1);
                }

                &.active {

                    &:before {
                        content: '';
                        position: absolute;
                        top: 13px;
                        right: 0;
                        border-style: solid;
                        border-width: 6px 6px 6px 0;
                        border-color: $transparent $color-grey-white $transparent $transparent;
                    }
                }

                &.imprint,
                &.privacy {
                    display: none;
                }
            }
        }
    }

    .enwrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        padding: 13px 15px;
        font-size: 0;
        text-align: right;
        background: $color-blue-logo-2;
        cursor: pointer;

        .icon {
            color: $color-grey-light;
            font-size: 14px;

            &.icon-arrows-right {
                display: none;
            }
        }
    }

    &.enwraped {

        nav {

            ul {
                width: 50px !important;

                li {

                    .text {
                        @include opacity(0);
                        @include transition(opacity, $duration-300ms, ease-out, 0s);
                    }
                }
            }
        }

        .enwrap {

            .icon {

                &.icon-arrows-left {
                    display: none;
                }

                &.icon-arrows-right {
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #interface-navigation {

        nav {

            ul {

                li {

                    &.imprint,
                    &.privacy {
                        display: block;
                    }
                }
            }
        }
    }
}
