@use '../../basics/globals' as *;

.mixin-form-uploadbox {

    .dropzone {
        display: block;
        min-height: 100px;
        border: 2px dashed $color-blue-middle;

        input {
            display: none;
        }

        .dz-message {
            padding: 30px;
            min-height: 100px;
            text-align: center;

            button {
                color: $color-green-middle;
                font-size: $font-size-22px;
                font-weight: 500;
            }

            .file-info {
                padding-top: 20px;

                span {
                    color: $color-grey-middle;
                    font-size: $font-size-16px;
                    line-height: 30px;
                }
            }
        }

        .dz-preview {
            clear: both;
            position: relative;
            margin: 0 20px;
            font-size: 0;
            border-top: 1px solid $color-blue-bright;

            .dz-image {
                float: left;
                display: inline-block;

                img[src] {
                    width: 120px;
                    margin: 10px 20px 10px 0;

                    &.img-fallback {
                        display: none;
                    }
                }
            }

            .dz-details {
                display: block;
                padding: 10px 20px 10px 0;

                .dz-size {
                    font-size: 0;

                    span {
                        font-size: $font-size-14px;
                    }

                    strong {
                        font-size: $font-size-14px;
                        font-weight: 700;
                    }
                }

                .dz-filename {

                    span {
                        display: inline-block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 100%;
                    }
                }
            }

            .dz-error-message {
                display: none;

                span {
                    display: block;
                    padding-bottom: 10px;
                    color: $color-red-error;
                    font-size: 16px;
                }
            }

            .dz-success-mark,
            .dz-error-mark {
                display: none;
            }

            .dz-remove {
                position: absolute;
                bottom: 7px;
                right: 0;
                color: $color-white;

                span {
                    text-indent: 0;
                }
            }

            &.dz-image-preview {

                &:after {
                    content: '';
                    clear: both;
                    display: block;
                }
            }

            &.dz-error {

                .dz-error-message {
                    display: block;
                }
            }
        }

        &:after {
            content: '';
            clear: both;
            display: block;
        }
    }

    .select-file {
        float: left;
    }

    .mixin-flash {

        &.max-files-reached {
            display: none;

            &.show {
                display: block;
            }
        }
    }
}
