@use '../../basics/globals' as *;

#clients-images {
    margin-top: 10px;

    .images-wrapper {
        display: flex;
        @include gap(0 10px);
        @include flex-wrap(wrap);

        .image-wrapper {
            position: relative;
            display: inline-block;
            overflow: hidden;
            margin-bottom: 10px;
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            @include flex(1 0 140px);

            img {
                width: 100%;
            }

            .enlarge {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                cursor: pointer;
            }

            .controls-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 2;
                height: 38px;
                overflow: hidden;

                .controls {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 38px;
                    z-index: 1;
                    padding-top: 0;
                    text-align: center;
                    background: $color-blue-black;
                    @include opacity(0);
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                    @include transition(all, 0.25s, $ease-in-out-cubic, 0s);

                    .control {
                        display: inline-block;
                        margin: 0 7px;
                        line-height: 38px;
                        cursor: pointer;

                        .icon {
                            color: $color-grey-white;
                            line-height: 38px;
                        }

                        &.select {
                            display: block;
                            position: relative;
                            top: 0;
                            height: 38px;

                            .text {
                                position: relative;
                                top: 6px;
                                color: $color-grey-white;
                                font-size: $font-size-16px;
                                text-transform: uppercase;
                                @include font-family('Exo 2');
                            }
                        }
                    }
                }

                &.controls-wrapper-overlay {

                    .controls {
                        background: $color-green-middle;
                    }
                }
            }

            .used-counter {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                padding: 5px 7px;
                color: $color-grey-white;
                font-size: $font-size-14px;
                line-height: 1em;
                background: $color-green-middle;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                &.is-null {
                    background: $color-blue-black;
                }
            }

            &.placeholder {
                margin-bottom: 0;
                pointer-events: none;
                @include opacity(0);
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: #000;
                @include opacity(0);
                @include transition(opacity, 0.25s, ease-out, 0s);
            }

            &:hover:not(.no-css-hover) {

                .controls-wrapper {

                    .controls {
                        top: 0;
                        @include opacity(1);
                    }
                }

                &:after {
                    @include opacity(0.5);
                }
            }
        }
    }
}

html.isTouch {

    #clients-images {

        .images-wrapper {

            .image-wrapper {

                .controls-wrapper {

                    .controls {
                        top: 0;
                        @include opacity(1);
                    }
                }

                &:after {
                    content: none;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #clients-images {

        .images-wrapper {

            .image-wrapper {
                width: calc(50% - 10px);
            }
        }
    }
}

@media screen and (max-width: 400px) {

    #clients-images {

        .images-wrapper {

            .image-wrapper {
                width: calc(50% - 5px);
            }
        }
    }
}
