@use '../../basics/globals' as *;

.mixin-overlay-header {
    display: flex;
    margin-bottom: 20px;
    @include flex-wrap(wrap);
    @include gap(15px);

    .text {
        display: inline-block;
        max-width: 100%;
        color: $color-blue-logo-2;
        font-size: $font-size-30px;
        font-weight: 500;
        line-height: 1.3em;
        text-overflow: ellipsis;
        overflow: hidden;
        @include font-family('Museo Slab');
    }

    .icon-warning {
        margin-top: -10px;
        color: $color-blue-logo-2;
        font-size: 50px;
    }

    .icon-checkmark {
        margin-top: -8px;
        padding: 10px;
        color: $color-green-middle;
        font-size: 30px;
        background: $color-green-bright;
        @include border-radius(50%, 50%, 50%, 50%);
    }

    .icon-bot {
        margin-top: -9px;
        padding: 10px;
        color: $color-grey-white;
        font-size: 30px;
        background: $color-blue-logo-2;
        @include border-radius(50%, 50%, 50%, 50%);
    }

    .icon-statistics {
        color: $color-green-middle;
        font-size: 36px;
    }
}
