@use '../../basics/globals' as *;

.mixin-article {
    position: relative;

    p {
        padding-bottom: 15px;
        letter-spacing: 0.2px;

        em,
        span {
            color: inherit;
            font: inherit;
            letter-spacing: inherit;
        }

        em {
            font-weight: 500;
        }

        span {
            font-size: $font-size-16px;

            &.link-style {
                display: inline;
                padding: 0;
                color: $color-blue-logo-1;

                &:hover:not(.no-css-hover) {
                    text-decoration: underline;
                }
            }
        }

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    .mixin-article-header {
        position: relative;
        z-index: 2;
        margin-bottom: 10px;

        h1,
        h2,
        h3,
        h4 {
            display: inline-block;
            hyphens: auto;

            &[data-trigger-href] {

                &:hover:not(.no-css-hover) {
                    text-decoration: underline;
                }
            }
        }

        .wrapper-icon {
            position: relative;
            height: 90px;
            margin-bottom: 40px;
            padding: 20px 0;
            text-align: center;

            .icon {
                color: $color-grey-white;
                font-size: 40px;

                &.icon-cutlery {
                    top: 5px;
                    left: 2px;
                }

                &.icon-order {
                    top: 8px;
                }

                &.icon-settings {
                    font-size: 50px;
                }
            }

            .mixin-image-lazy {
                position: relative;
                top: 5px;
                z-index: 1;
                height: 40px;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 50%;
                @include transform(translate(-50%, 0));
                @include border-radius(50%, 50%, 50%, 50%);
            }

            &:before {
                top: 0;
                height: 90px;
                width: 90px;
                background: $color-blue-logo-1;
            }

            &:after {
                top: 7px;
                height: 70px;
                width: 70px;
                border: 3px solid $color-grey-white;
                background: $color-blue-logo-2;
            }

            &:has(.mixin-image-lazy) {

                &:after {
                    background: $color-grey-white;
                }
            }
        }

        .preline-wrapper {
            display: flex;
            position: relative;
            @include flex-wrap(wrap);
            @include gap(10px);

            .preline-item {
                position: relative;
                padding-bottom: 15px;
                max-width: 100%;

                > a,
                > span {
                    display: inline-block;
                    padding: 5px 15px;
                    max-width: 100%;
                    color: $color-grey-white !important;
                    font-size: $font-size-14px !important;
                    font-weight: 500 !important;
                    line-height: 28px !important;
                    text-transform: uppercase;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    background: $color-blue-dark;
                    overflow: hidden;
                    @include user-select(none);
                    @include border-radius(100px, 100px, 100px, 100px);
                    @include font-family('Exo 2');

                    &:hover:not(.no-css-hover) {
                        text-decoration: none;
                    }
                }

                .mixin-dots {
                    left: -10px;
                    width: 32px;
                    height: 100%;
                    background: $color-blue-dark;
                    @include border-radius(0, 100px, 100px, 0);

                    .dot {
                        background: $color-grey-white;
                    }
                }

                .dropdown-wrapper {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    padding: 0 20px;
                    background: $color-grey-white;
                    pointer-events: none;
                    @include opacity(0);
                    @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.2));
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                    @include transform-origin(300px, 0);
                    @include transform(perspective(300px) translate(0, 0) rotateX(-7deg));
                    @include transition(all, 0.4s, $ease-in-out-cubic, 0s);

                    .dropdown-item {
                        display: block;
                        font-size: 0;
                        border-bottom: 1px solid $color-blue-logo-2-opacity-010p;

                        a {
                            display: block;
                            position: relative;
                            padding: 5px 0;

                            span {
                                color: $color-blue-dark;
                                font-size: $font-size-16px;
                                font-weight: 600;
                                white-space: nowrap;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                top: -2px;
                                bottom: -2px;
                                left: -20px;
                                width: 5px;
                                background: $color-green-middle;
                                @include opacity(0);
                                @include transition(opacity, 0.3s, ease-out, 0s);
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                top: -2px;
                                bottom: -2px;
                                left: -20px;
                                right: -20px;
                                background: $color-blue-logo-2-opacity-005p;
                                @include opacity(0);
                                @include transition(opacity, 0.3s, ease-out, 0s);
                            }

                            &.active,
                            &:hover:not(.no-css-hover) {

                                &:before,
                                &:after {
                                    @include opacity(1);
                                }
                            }
                        }

                        &:first-child {

                            a {
                                padding-top: 10px;

                                &:before {
                                    top: 0;
                                    @include border-radius($border-radius-3px, 0, 0, 0);
                                }

                                &:after {
                                    top: 0;
                                    @include border-radius($border-radius-3px, $border-radius-3px, 0, 0);
                                }

                                &.active,
                                &:hover:not(.no-css-hover) {

                                    span {

                                        &:before {
                                            content: '';
                                            position: absolute;
                                            bottom: 100%;
                                            left: 0;
                                            border-left: 10px solid $transparent;
                                            border-right: 10px solid $transparent;
                                            border-bottom: 10px solid $color-blue-logo-2-opacity-005p;
                                            @include transform(translate(50%, 0));
                                        }
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border: none;

                            a {

                                &:before {
                                    bottom: 0;
                                    @include border-radius(0, 0, 0, $border-radius-3px);
                                }

                                &:after {
                                    @include border-radius(0, 0, $border-radius-3px, $border-radius-3px);
                                }
                            }
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 100%;
                        left: 20px;
                        border-left: 10px solid $transparent;
                        border-right: 10px solid $transparent;
                        border-bottom: 10px solid $color-grey-white;
                        @include transform(translate(50%, 0));
                    }
                }

                &.with-dropdown {
                    max-width: calc(100% - 22px);
                    white-space: nowrap;

                    > a,
                    > span {
                        @include border-radius(100px, 0, 0, 100px);
                    }
                }

                &.show-dropdown {

                    .dropdown-wrapper {
                        pointer-events: auto;
                        @include opacity(1);
                        @include transform(perspective(300px) translate(0, 0) rotateX(0deg));
                    }
                }
            }
        }

        .subline {
            padding: 15px 0;
            font-weight: 500;
            line-height: 1.5em;
        }

        &.preline-right {

            .preline-wrapper {
                float: right;
                margin: 0;
            }
        }

        &.border-bottom {
            padding-bottom: 10px;
            border-bottom: 1px solid $color-blue-logo-2-opacity-010p;
        }

        &.margin-bottom-huge {
            margin-bottom: 50px;
        }
    }

    .mixin-article-navigation {
        position: absolute !important;
        top: 3px;
        right: 0;
        z-index: 5;
        font-size: 0;

        a,
        > span {
            cursor: pointer;
        }

        + .mixin-article-header {

            h1,
            h2,
            h3,
            h4, {
                padding-right: 35px;
            }
        }
    }

    .mixin-article-list {
        display: flex;
        position: relative;
        z-index: 1;
        margin-top: 10px;
        padding: 10px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        @include flex-wrap(wrap);
        @include gap(0 20px);

        > li {
            display: flex;
            position: relative;
            margin-bottom: 10px;
            color: $color-green-middle;
            font-size: $font-size-18px;
            font-weight: 500;
            align-content: baseline;
            text-overflow: ellipsis;
            overflow: hidden;
            @include flex(1 0 100%);
            @include flex-wrap(wrap);

            a,
            [data-overlay-url] {
                text-decoration: underline;
            }

            em {
                font-weight: 500 !important;
            }

            > .headline {
                display: flex;
                color: inherit !important;
                font: inherit !important;
                text-overflow: ellipsis;
                overflow: hidden;
                @include flex(1 1 100%);

                h4,
                a,
                em {
                    display: inline-block !important;
                    margin: 3px 0 0 0 !important;
                    color: inherit !important;
                    font: inherit !important;
                    line-height: 1.3em !important;
                    max-width: 100%;
                    overflow-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @include flex(0 1 auto);

                    > .icon {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    &:hover:not(.no-css-hover) {

                        > .icon {
                            color: $color-green-middle;
                            border-color: $color-green-middle;
                        }
                    }
                }

                > .icon,
                > .number {
                    margin-right: 10px;
                    color: $color-green-middle;
                    font-size: 14px;
                    line-height: 28px;
                    @include flex(0 0 auto);

                    &.icon-checkmark {
                        top: 1px;
                        width: 22px;
                        font-size: 13px;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 2px;
                            left: 0;
                            z-index: -1;
                            width: 22px;
                            height: 22px;
                            background: $color-green-bright;
                            @include border-radius(50%, 50%, 50%, 50%);
                        }
                    }

                    &.icon-x,
                    &.icon-warning {
                        top: -1px;
                        left: 1px;
                        font-size: 22px;
                        color: $color-grey-dark;
                    }

                    &.number {
                        display: inline-block;
                        position: relative;
                        top: 1px;
                        width: 22px;
                        height: 22px;
                        color: $color-grey-white;
                        font-size: $font-size-14px;
                        font-weight: 500;
                        line-height: 27px;
                        text-align: center;
                        @include font-family('Museo Slab');

                        &:after {
                            content: '';
                            position: absolute;
                            top: 2px;
                            left: 0;
                            z-index: -1;
                            width: 22px;
                            height: 22px;
                            background: $color-purple-middle;
                            @include border-radius(50%, 50%, 50%, 50%);
                        }
                    }
                }
            }

            > .content {
                @include flex(1 1 100%);

                p {
                    color: $color-blue-grey;
                    font-size: $font-size-16px;
                    line-height: 1.4;
                    overflow-wrap: break-word;

                    a {
                        max-width: 100%;
                        overflow-wrap: break-word;

                        &:hover:not(.no-css-hover) {
                            text-decoration: underline;
                        }
                    }

                    span {
                        max-width: 100%;
                        color: inherit;
                        font: inherit;
                        link-height: inherit;
                        overflow-wrap: break-word;

                        &[data-overlay-url],
                        &[data-overlay-src],
                        &[data-overlay-data],
                        &[data-overlay-content] {
                            text-decoration: underline;
                        }
                    }
                }

                ul {
                    margin-top: 5px;

                    li {
                        position: relative;
                        margin-bottom: 10px;
                        padding-left: 50px;
                        color: $color-blue-grey;
                        font-size: $font-size-16px;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 9px;
                            left: 35px;
                            width: 6px;
                            height: 6px;
                            background: $color-grey-dark;
                            @include border-radius(50%, 50%, 50%, 50%);
                        }

                        &:first-child {

                            &:last-child {
                                padding-left: 32px;

                                &:before {
                                    content: none;
                                }
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .wrapper-icon-tooltip {
                position: absolute !important;
                z-index: 1;
                top: 0;
                left: 0;
                width: 22px;
                height: 30px;
            }

            &.color-grey-dark {
                color: $color-grey-dark;
            }

            &.color-purple-middle {
                color: $color-purple-middle;
            }

            &.has-icon,
            &.has-number {

                p {
                    padding-left: 34px;
                }

                &.has-number {
                    color: $color-purple-middle;
                }

                &.has-icon-x,
                &.has-icon-warning {
                    color: $color-grey-dark;
                }

                &.has-icon-right {

                    p {
                        padding-right: 50px;
                    }
                }
            }

            // &.has-teaser {}
        }

        &.clms-2 {

            > li {
                @include flex(1 1 calc(50% - 20px));
            }

            &:after {
                @include flex(1 1 calc(50% - 20px));
            }
        }

        &.clms-3 {

            > li {
                @include flex(1 1 calc(33.333333% - 20px));
            }

            &:after {
                @include flex(1 1 calc(33.333333% - 20px));
            }
        }

        &:hover:not(.no-css-hover),
        &.touch-active {
            z-index: 2;
        }
    }

    .mixin-article-process {
        display: flex;
        position: relative;
        z-index: 1;
        @include flex-wrap(wrap);
        @include gap(20px);

        li {
            padding: 7px;
            max-width: 100%;
            @include flex(1 0 100%);

            .header {
                display: flex;
                position: relative;
                margin-bottom: $border-radius-15px;
                border: 1px solid $color-blue-logo-1;
                border-left: none;
                border-bottom: none;
                @include border-radius(0, $border-radius-15px, 0, 0);

                .number {
                    position: relative;
                    z-index: 1;
                    margin: 3px 0 -12px 0;
                    width: 73px;
                    height: 73px;
                    line-height: 76px;
                    color: $color-blue-logo-1;
                    font-size: $font-size-30px;
                    text-align: center;
                    @include flex(0 0 auto);
                    @include font-family('Museo Slab');

                    &:after {
                        content: '';
                        position: absolute;
                        top: 5px;
                        bottom: 5px;
                        left: 5px;
                        right: 5px;
                        z-index: -1;
                        background: $color-grey-white;
                        @include border-radius(calc($border-radius-15px - 2px), calc($border-radius-15px - 2px), calc($border-radius-15px - 2px), calc($border-radius-15px - 2px));
                        @include box-shadow(10px, 10px, 15px, 0, rgba(0, 0, 0, 0.2));
                    }
                }

                .headline {
                    display: flex;
                    position: relative;
                    margin: 3px 0 -12px 0;
                    padding: 20px 25px 20px 15px;
                    max-width: calc(100% - 73px);
                    @include flex(1 1 auto);

                    .text {
                        color: $color-grey-white;
                        font-size: $font-size-22px;
                        font-weight: 400;
                        white-space: nowrap;
                        @include flex(1 1 auto);
                    }

                    .icon {
                        top: 7px;
                        margin-left: 10px;
                        color: $color-grey-white;
                        font-size: 22px;
                        @include flex(0 0 auto);
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 5px;
                        bottom: 5px;
                        left: -$border-radius-15px;
                        right: 7px;
                        z-index: -1;
                        background: $color-blue-logo-1;
                        @include border-radius(0, calc($border-radius-15px - 2px), calc($border-radius-15px - 2px), 0);
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -4px;
                    left: -7px;
                    width: 5px;
                    height: 5px;
                    border: 1px solid $color-blue-logo-1;
                    @include border-radius(50%, 50%, 50%, 50%);
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: calc(-1px - $border-radius-15px);
                    right: -1px;
                    width: $border-radius-15px;
                    height: $border-radius-15px;
                    border: 1px solid $color-blue-logo-1;
                    border-top: none;
                    border-left: none;
                    @include border-radius(0, 0, $border-radius-15px, 0);
                }
            }

            .info {
                position: relative;
                margin-right: $border-radius-15px;
                padding: 20px calc(20px - $border-radius-15px) 20px 20px;
                border: 1px solid $color-blue-logo-1;
                border-right: none;
                @include border-radius($border-radius-15px, 0, 0, $border-radius-15px);

                p {
                    font-size: $font-size-16px;
                    font-weight: 400;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: calc($border-radius-15px - 7px);
                    right: calc(-4px - $border-radius-15px);
                    width: 7px;
                    height: 7px;
                    background: $color-blue-logo-1;
                    @include border-radius(50%, 50%, 50%, 50%);
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -1px;
                    right: calc(-1px - $border-radius-15px);
                    width: $border-radius-15px;
                    height: $border-radius-15px;
                    border: 1px solid $color-blue-logo-1;
                    border-top: none;
                    border-left: none;
                    @include border-radius(0, 0, $border-radius-15px, 0);
                }
            }

            &.color-blue-logo-2 {

                .header {
                    border-color: $color-blue-logo-2;

                    .number {
                        color: $color-blue-logo-2;
                    }

                    .headline {

                        &:after {
                            background: $color-blue-logo-2;
                        }
                    }

                    &:before,
                    &:after {
                        border-color: $color-blue-logo-2;
                    }
                }

                .info {
                    border-color: $color-blue-logo-2;

                    &:before {
                        background: $color-blue-logo-2;
                    }

                    &:after {
                        border-color: $color-blue-logo-2;
                    }
                }
            }

            &.color-green-middle {

                .header {
                    border-color: $color-green-middle;

                    .number {
                        color: $color-green-middle;
                    }

                    .headline {

                        &:after {
                            background: $color-green-middle;
                        }
                    }

                    &:before,
                    &:after {
                        border-color: $color-green-middle;
                    }
                }

                .info {
                    border-color: $color-green-middle;

                    &:before {
                        background: $color-green-middle;
                    }

                    &:after {
                        border-color: $color-green-middle;
                    }
                }
            }

            &.color-purple-middle {

                .header {
                    border-color: $color-purple-middle;

                    .number {
                        color: $color-purple-middle;
                    }

                    .headline {

                        &:after {
                            background: $color-purple-middle;
                        }
                    }

                    &:before,
                    &:after {
                        border-color: $color-purple-middle;
                    }
                }

                .info {
                    border-color: $color-purple-middle;

                    &:before {
                        background: $color-purple-middle;
                    }

                    &:after {
                        border-color: $color-purple-middle;
                    }
                }
            }
        }
    }

    .mixin-article-accordion {
        @include gap(20px);

        > li {
            cursor: pointer;
            @include user-select(none);

            .tile-wrapper {

                .mixin-article-navigation {

                    .icon {
                        border: none !important;
                    }
                }

                .mixin-article-header {
                    margin-bottom: 0;
                }

                .folding-wrapper-outer {
                    display: none;
                    overflow: hidden;

                    .folding-wrapper-inner {
                        margin-top: 30px;
                        padding-top: 30px;
                        border-top: 1px solid $color-blue-logo-2-opacity-010p;
                        @include opacity(0);
                        @include transition(opacity, $duration-300ms, ease-out, 0s);

                        p {
                            color: $color-blue-grey;
                        }

                        ul,
                        ol {
                            display: flex;
                            flex-direction: column;
                            @include gap(10px 0);

                            li {
                                padding-left: 20px;
                                margin-left: 20px;
                                @include flex(1 1 100%);

                                &::marker {
                                    color: $color-blue-logo-2;
                                    @include font-family('Museo Slab');
                                }
                            }
                        }

                        ul {

                            li {
                                position: relative;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 8px;
                                    left: -18px;
                                    width: 8px;
                                    height: 8px;
                                    border: 2px solid $color-blue-logo-2;
                                    @include border-radius(50%, 50%, 50%, 50%);
                                }
                            }
                        }
                    }
                }
            }

            &.show {

                .tile-wrapper {

                    .mixin-article-navigation {

                        .icon {
                            @include rotate-z(0, 90deg, 50%, 50%);
                        }
                    }

                    .folding-wrapper-outer {

                        .folding-wrapper-inner {
                            @include opacity(1);
                            @include transition(opacity, $duration-300ms, ease-out, $duration-200ms);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-article {

        > .clm-wrapper,
        > .flex-wrapper {

            &.key-value {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .mixin-article-list {

            &.clms-3 {

                > li {
                    @include flex-basis(100%);
                }
            }
        }

        .mixin-article-process {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 600px) {

    .mixin-article {

        .mixin-article-list {

            &.clms-3 {

                > li {
                    @include flex(1 1 calc(50% - 20px));
                }

                &:after {
                    @include flex(1 1 calc(50% - 20px));
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-article {

        p {
            font-weight: 400;

            em {
                font-weight: 600;
            }
        }

        .mixin-article-header {

            .subline {
                font-weight: 600;
            }
        }

        .mixin-article-list {

            > li {

                em {
                    font-weight: 600 !important;
                }

                .headline {
                    font-weight: 600 !important;
                }
            }

            &.clms-2,
            &.clms-3 {

                > li {
                    @include flex(1 1 100%);
                }

                &:after {
                    @include flex(1 1 100%);
                }
            }
        }
    }
}
