@use '../../basics/globals' as *;

#clients-overlay-translate-string {
    width: 350px;

    .wrapper-success {
        display: none;

        .mixin-article-list {

            li {
                display: none;

                &.show {
                    display: flex;
                }
            }
        }
    }

    .wrapper-warning {
        display: none;
    }

    &.show-success {
        width: 500px;

        .wrapper-translate {
            display: none;
        }

        .wrapper-success {
            display: block;
        }
    }

    &.show-warning {

        .wrapper-translate {
            display: none;
        }

        .wrapper-warning {
            display: block;
        }
    }
}
