@use '../../basics/globals' as *;

#overlay-error {
    max-width: 600px;

    .mixin-overlay-header {

        .text,
        .icon {
            color: $color-red-error;
        }
    }
}
