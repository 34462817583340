@use '../basics/globals' as *;

#page-maintenance {
    margin: 0;
    padding: 0;
    height: 100%;
    text-align: center;
    background-image: none;
    overflow-y: auto;
    @include opacity(1);

    h1 {
        font-size: $font-size-30px;
        line-height: 1.3em;
    }

    form {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        padding: 0 20px;
        max-width: 400px;
        text-align: left;

        label {

            &.mandatory {

                &:after {
                    content: '*';
                    margin-left: 5px;
                }
            }
        }

        input {
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

            &[type=submit] {
                padding: $form-padding;
                color: $color-white;
                background: $color-grey-dark;
                text-transform: uppercase;
            }
        }
    }

    .icon-excavator {
        margin-bottom: 30px;
        color: $color-grey-dark;
        font-size: 100px;
    }

    .subline {
        margin-top: 10px;
        font-size: $font-size-20px;
        @include opacity(0.5);
    }
}
