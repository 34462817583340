@use '../../basics/globals' as *;

.mixin-dots {
    display: inline-block;
    position: relative;

    .dot {
        position: absolute;
        left: 10px;
        width: 4px;
        height: 4px;
        background: $color-grey-dark;
        @include border-radius(50%, 50%, 50%, 50%);

        &:nth-child(1) {
            top: 10px;
        }

        &:nth-child(2) {
            top: 17px;
        }

        &:nth-child(3) {
            top: 24px;
        }
    }
}
