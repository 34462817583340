@use '../basics/globals' as *;

.mixin-switcher {
    display: flex;
    position: relative;
    font-size: 0;
    border: 1px solid #000;
    cursor: pointer;
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

    span {
        display: inline-block;
        padding: 2px 5px;
        min-width: 20px;
        min-height: 18px;
        color: $transparent;
        font-size: $font-size-12px;
        line-height: 1em;
        text-align: center;
        background: #000;
        border: 1px solid #fff;
        @include flex(1 0 auto);


        &.active {
            color: #000;
            background: $transparent;
            border-color: $transparent;
        }
    }
}
