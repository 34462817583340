@use '../../basics/globals' as *;

.mixin-interface-vue-loading {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    height: calc(100vh - 140px);

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 200px;
        background-image: url('../images/assets/app/layout/loading.svg?#{$timestamp}');
        background-size: 100%;
        @include transform(translate(-50%, -50%));
    }
}
