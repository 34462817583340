@use '../../basics/globals' as *;

#accounts-overlay-order,
#interfaces-overlay-order {
    width: 450px;

    .key-value {
        padding-right: 35px;

        .clm {
            position: relative;

            .icon {
                position: absolute;
                left: auto;
                right: -35px;
            }
        }

        &.flex-wrapper {
            display: flex;
            @include flex-wrap(wrap);
            @include gap(20px);

            .flex-item {
                position: relative;
                @include flex(1 1);

                form {
                    margin: -5px 0;
                    width: 100%;
                }

                .icon {
                    position: absolute;
                    top: 0;
                    left: auto;
                    right: -35px;
                }

                &:first-child {
                    overflow: hidden;
                }

                &:last-child {
                    justify-content: right;

                    p {
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    ul.courses {
        font-size: 0;
        padding-bottom: 5px;
        border-bottom: 1px solid $color-blue-bright;

        > li {

            h5 {
                margin: 5px 0;
                padding: 5px 0;
                font-size: $font-size-12px;
                text-align: center;
                text-transform: uppercase;
                border-top: 1px solid $color-blue-bright;
                border-bottom: 1px solid $color-blue-bright;
            }

            ul.items {
                font-size: 0;

                > li {
                    position: relative;
                    padding-right: 60px;
                    font-size: 0;

                    > span {
                        display: inline-block;
                        font-size: $font-size-16px;
                        font-weight: 500;
                        line-height: 1.4em;

                        &.amount {
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-variant: tabular-nums;
                        }

                        &.label {
                            width: 100%;
                            padding-left: 35px;
                            text-overflow: ellipsis;
                            overflow: hidden;

                            .label-cost {
                                display: inline;
                                font: inherit;
                                line-height: inherit;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        }

                        &.price {
                            position: absolute;
                            top: 0;
                            right: 0;
                        }

                        &.preparation {
                            display: block;
                            margin-left: 35px;
                            color: $color-blue-grey;
                            font-size: $font-size-12px;
                            font-weight: 300;
                        }
                    }

                    ul.add-ons {
                        width: 100%;
                        margin-left: 35px;
                        font-size: 0;

                        li {
                            font-size: 0;

                            .label {
                                color: $color-blue-grey;
                                font-size: $font-size-12px;
                            }
                        }
                    }
                }
            }

            &:first-child {

                h5 {
                    font-weight: 700 !important;
                    border-top: none;
                }
            }
        }
    }

    .subtotal,
    .tip,
    .total,
    .outstanding {

        span {
            display: inline-block;
            font-size: $font-size-16px;
            font-weight: 500;
            line-height: 1.4em;

            &.label {

                &:first-letter {
                    text-transform: capitalize;
                }
            }

            &.amount {
                float: right;
                font-variant: tabular-nums;
            }
        }

        &.subtotal {
            padding-top: 5px;
            font-size: 0;
        }

        &.tip {
            padding-bottom: 5px;
            font-size: 0;
            border-bottom: 1px solid $color-blue-bright;
        }

        &.total {
            padding: 5px 0;
            font-size: 0;
            border-bottom: 1px solid $color-blue-bright;

            span {
                font-weight: 600;
            }
        }

        &.outstanding {
            padding-top: 5px;

            span {
                font-weight: 600;
            }
        }
    }
}
