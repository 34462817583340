@use '../../basics/globals' as *;

#demos-overlay-choose {
    width: 600px;

    .tiles-wrapper {
        padding: 0 30px;
    }
}

@media screen and (max-width: 600px) {

    #demos-overlay-choose {

        .tiles-wrapper {
            padding: 0;

            .tile-wrapper {
                border: 1px solid $color-blue-logo-2;
                @include box-shadow-none();
            }
        }
    }
}
