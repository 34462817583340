@use '../../basics/globals' as *;

.mixin-interface-vue-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 180px);
    text-align: center;

    .icon {
        margin-bottom: 30px;
        font-size: 60px !important;
    }

    h3 {
        margin: 0 50px 20px 50px;
        font-size: $font-size-30px !important;
        font-weight: 600;
        @include font-family('Open Sans');
    }

    p {
        margin: 0 auto;
        max-width: 500px;
    }
}
