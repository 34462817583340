@use '../../basics/globals' as *;

.mixin-btn {
    display: flex;
    clear: both;
    padding-top: 20px;
    font-size: 0;
    @include user-select(none);
    @include flex-wrap(wrap);
    @include gap(20px);

    &.btn-hover .btn,
    .btn.btn-hover {

        &.btn {

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                outline: 0 solid $color-blue-logo-2;
                @include border-radius(100px, 100px, 100px, 100px);
                @include transition(all, 0s, ease-out, 0s);
            }

            &:hover:not(.no-css-hover) {

                &:after {
                    top: -8px;
                    bottom: -8px;
                    left: -8px;
                    right: -8px;
                    z-index: 1;
                    outline-width: 1px;
                    @include opacity(0);
                    @include transition(all, 0.3s, ease-out, 0s);
                }
            }
        }
    }

    .btn {
        position: relative;
        display: flex;
        padding: 10px 15px;
        max-width: 100%;
        height: 38px;
        color: $color-grey-white;
        font-size: $font-size-16px;
        line-height: 1em;
        text-align: center;
        text-decoration: none !important;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: $color-blue-logo-2;
        border: 1px solid $color-blue-logo-2;
        cursor: pointer;
        @include gap(15px);
        @include flex(0 0 auto);
        @include border-radius(100px, 100px, 100px, 100px);
        @include transition(all, 0.5s, ease-out, 0s);

        .text {
            color: inherit;
            font: inherit;
            line-height: inherit;
            text-align: inherit;
            text-transform: inherit;
            text-overflow: inherit;
            white-space: inherit;
            overflow: hidden;
            @include flex(1 1 auto);
            @include font-family('Exo 2');
        }

        .icon {
            margin: -1px 0;
            color: inherit;
            @include flex(0 0 auto);
        }

        &.bg-color-transparent {
            color: $color-blue-logo-2;
            font-weight: 500;
            background: $transparent;
        }

        &.bg-color-green-middle {
            border-color: $color-green-middle;
            background: $color-green-middle;

            &:after {
                outline-color: $color-green-middle !important;
            }
        }

        &.bg-color-purple-middle {
            border-color: $color-purple-middle;
            background: $color-purple-middle;

            &:after {
                outline-color: $color-purple-middle !important;
            }
        }

        &.bg-color-grey-dark {
            border-color: $color-grey-dark;
            background: $color-grey-dark;

            &:after {
                outline-color: $color-grey-dark !important;
            }
        }

        &.bg-color-white {
            color: $color-blue-dark;
            font-weight: 500;
            border-color: $color-white;
            background: $color-white;

            &:after {
                outline-color: $color-white !important;
            }
        }

        &.border-color-green-middle {
            color: $color-green-middle;
            font-weight: 500;
            border-color: $color-green-middle;

            &:after {
                outline-color: $color-green-middle !important;
            }
        }

        &.border-color-purple-middle {
            color: $color-purple-middle;
            font-weight: 500;
            border-color: $color-purple-middle;

            &:after {
                outline-color: $color-purple-middle !important;
            }
        }

        &.border-color-white {
            color: $color-white;
            border-color: $color-white;

            &:after {
                outline-color: $color-white !important;
            }
        }

        &.disabled {
            cursor: default !important;
            background: $color-blue-bright !important;
            border-color: $color-blue-bright !important;

            &:after {
                content: none;
            }

            &.bg-color-transparent {
                color: $color-blue-bright !important;
                background: $transparent !important;
            }
        }

        &.form-inline-padding {
            height: 34px;
            padding-top: 7px;
        }
    }

    .teaser {
        min-width: 250px;
        max-width: 500px;
        color: $color-blue-bright;
        @include flex(1 1);
    }

    &:has(.teaser) {
        justify-content: space-between !important;
    }

    &.no-padding-top {
        padding-top: 0;
    }
}

@media screen and (max-width: 500px) {

    .mixin-btn {

        .btn {
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 400px) {

    .mixin-btn {

        .btn {
            width: 100%;
            margin: 0;
        }
    }
}
