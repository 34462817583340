@use '../basics/globals' as *;

#page-error {

    .icon {
        color: $color-blue-logo-1;
    }

    &:before {
        content: none;
    }
}
