@use '../../basics/globals' as *;

#contracts-overlay-accept-terms {
    width: 600px;

    form {
        margin-top: 20px;

        .select2 {
            margin-bottom: 30px;
        }
    }
}
