@use '../../basics/globals' as *;

#accounts-overlay-payment-details {
    width: 450px;

    .clm-wrapper {

        &.key-value {
            padding-right: 35px;

            .clm {
                position: relative;

                .icon {
                    position: absolute;
                    top: -1px;
                    left: auto;
                    right: -35px;
                }
            }
        }
    }

    ul.orders {
        margin: 10px 0 5px 0;
        padding-bottom: 5px;
        font-size: 0;
        border-bottom: 1px solid $color-blue-bright;

        li {
            position: relative;
            margin-bottom: 5px;
            padding: 0 60px 3px 0;
            font-size: 0;

            .number,
            .price {
                display: inline-block;
                font-size: $font-size-16px;
                font-weight: 500;
                line-height: 1.4em;

                &.number {
                    padding-right: 60px;
                }

                &.price {
                    position: absolute;
                    top: 0;
                    right: 35px;
                }

                &:hover:not(.no-css-hover) {
                    text-decoration: underline;
                }
            }

            .icon {
                position: absolute;
                top: -1px;
                right: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .total {

        span {
            display: inline-block;
            font-size: $font-size-16px;
            font-weight: 600;
            line-height: 1.4em;

            &.label {

                &:first-letter {
                    text-transform: capitalize;
                }
            }

            &.amount {
                float: right;
                padding-right: 35px;
                font-variant: tabular-nums;
            }
        }
    }
}
