@use '../../basics/globals' as *;

.mixin-share-links {

    li {

        a {

            .icon {
                @include transition(color, 0.3s, ease-out, 0s);
            }

            &:hover:not(.no-css-hover) {

                .icon {
                    color: $color-blue-logo-1;
                }
            }
        }
    }
}
