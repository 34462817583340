@use '../../basics/globals' as *;

#overlay {
    min-width: 20px;

    > .outer {

        > .wrapper {
            background: $color-grey-white repeating-linear-gradient(120deg, $transparent, $transparent 50%, $color-blue-logo-2-opacity-005p 0, $color-blue-logo-2-opacity-005p 100%);
            @include border-radius($border-radius-30px, $border-radius-30px, $border-radius-30px, $border-radius-30px);

            > .back {
                width: 24px;
                height: 24px;
                border: 1px solid $transparent;

                &:before,
                &:after {
                    top: 10px;
                    width: 11px;
                    background-color: $color-grey-dark;
                }
            }

            > .close {
                width: 24px;
                height: 24px;
                border: 1px solid $transparent;

                &:before,
                &:after {
                    top: 10px;
                    width: 18px;
                    background-color: $color-grey-dark;
                }

            }

            > .content {
                max-width: 1200px;
                padding-top: 20px;

                h5 {
                    position: relative;
                    margin-bottom: 5px;
                    color: $color-blue-logo-2;
                    font-weight: 600;
                    line-height: 1.3em;

                    a {
                        color: inherit;
                        font: inherit;
                        line-height: inherit;

                        &:hover:not(.no-css-hover) {
                            text-decoration: underline;
                        }
                    }
                }

                p {
                    margin-bottom: 10px;
                    line-height: 1.4em;

                    &.text-icon-left {

                        .icon {
                            top: 1px;
                            margin-right: 10px;
                            font-size: 22px;
                        }
                    }

                    &.text-icon-right {

                        .icon {
                            top: 2px;
                            margin-left: 10px;
                            font-size: 22px;
                        }
                    }

                    &.bold {
                        font-weight: 600;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                table {

                    .mixin-btn {
                        margin-top: 0;
                    }
                }

                .ctn-block {
                    margin-bottom: 20px;
                    padding: 10px;
                    border: 1px solid $color-blue-bright;
                    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                    h3 {
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        color: $color-grey-dark;
                        font-size: $font-size-22px;
                        text-align: center;
                        border-bottom: 1px solid $color-blue-logo-2-opacity-010p;
                        @include font-family('Open Sans');

                        span {
                            position: relative;
                            top: 6px;
                            margin-left: 5px;
                            color: $color-green-middle;
                            font-size: $font-size-12px;
                            font-weight: 600;
                            line-height: 1em;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .mixin-interface-vue-error {
                    max-width: 400px;
                    height: auto;
                    margin: 0 auto;
                }

                .border-top {
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid $color-blue-logo-2-opacity-010p;
                }

                .border-bottom {
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $color-blue-logo-2-opacity-010p;
                }

                > .module {
                    max-width: 100%;
                }

                [id^=interfaces-overlay] {

                    .clm-wrapper {

                        &.key-value {

                            .clm {

                                .select2-container {
                                    position: relative;
                                    top: -5px;
                                    margin-bottom: -10px !important;
                                }
                            }
                        }
                    }
                }
            }

            > .loading {
                z-index: 10;
                overflow: hidden;
                background: $color-grey-white repeating-linear-gradient(120deg, $transparent, $transparent 50%, $color-blue-logo-2-opacity-005p 0, $color-blue-logo-2-opacity-005p 100%);

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 200px;
                    height: 200px;
                    background-image: url('../images/assets/app/layout/loading.svg?#{$timestamp}');
                    background-size: 100%;
                    @include transform(translate(-50%, -50%));
                }

                &:after {
                    content: attr(data-text);
                    z-index: 999999999;
                    font-size: $font-size-16px;
                    font-weight: 600;
                    text-align: center;
                }
            }
        }
    }

    &.loading {

        > .outer {

            > .wrapper {

                > .content {
                    min-width: 150px;
                    min-height: 120px;
                }
            }
        }
    }
}

html:not(.isMobile) {

    #overlay {

        > .outer {

            > .wrapper {
                scrollbar-color: $color-blue-bright $color-blue-logo-2-opacity-005p;
            }
        }
    }
}

@media screen and (max-width: 1100px) {

    body {

        &.sidebar-badges-is-visible {

            #overlay {
                bottom: 70px;
            }
        }
    }
}

@media screen and (max-width: 900px) {

    #overlay {

        .clm-wrapper:not(.division-header):not(.key-value):not(.choose-buttons) {

            &.equal-distribution {
                margin: 0 !important;
            }

            .clm {
                width: 100%;
                padding: 0 !important;

                .mixin-form-field-info {
                    right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    #overlay {

        > .outer {

            > .wrapper {
                padding: 15px;
            }
        }
    }
}

@media screen and (max-width: 400px) {

    body {

        &.sidebar-badges-is-visible {

            #overlay {
                bottom: 60px;
            }
        }
    }
}
