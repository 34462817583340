@use '../../basics/globals' as *;

.mixin-qr-code {
    display: inline-block;
    position: relative;
    background: $color-blue-logo-1;
    @include border-radius($border-radius-15px, $border-radius-15px, $border-radius-15px, $border-radius-15px);

    span {
        display: block;
        position: relative;
        padding-top: 2px;
        color: $color-white;
        font-size: $font-size-16px;
        font-weight: 500;
        height: 30px;
        text-align: center;
        text-transform: uppercase;
        @include font-family('Exo 2');
    }

    .qr-code-wrapper {
        position: relative;
        margin: 10px 10px 5px 10px;
        width: 100px;
        height: 100px;

        canvas {
            width: 100%;
            height: 100%;
            @include opacity(0);
        }

        img {
            width: 100px;
            height: 100px;
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 5px;
        bottom: 30px;
        left: 5px;
        right: 5px;
        background: $color-grey-white;
        @include border-radius(calc($border-radius-15px - 5px), calc($border-radius-15px - 5px), calc($border-radius-15px - 5px), calc($border-radius-15px - 5px));
    }
}
