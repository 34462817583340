@use '../../basics/globals' as *;

.mixin-choose {

    .choose-buttons {
        margin-bottom: 20px;
        text-align: center;

        .clm {
            margin: 0 auto;
            padding: 0 20px;

            .wrapper {
                position: relative;
                height: 100%;
                cursor: pointer;

                h4 {
                    margin: 20px 0 5px 0 !important;
                    text-transform: uppercase;
                    justify-content: center;
                    font-weight: 600;
                    line-height: 1.3em;
                }

                .icon {
                    width: 60px;
                    height: 60px;
                    font-size: 32px;
                    text-align: center;
                    @include transition(color, $duration-100ms, ease-out, 0s);

                    &.icon-drink {

                        &:before {
                            top: 14px;
                            left: 1px;
                        }
                    }

                    &.icon-rocket {

                        &:before {
                            top: 15px;
                            left: 1px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: $color-blue-shiny;
                        @include border-radius(50%, 50%, 50%, 50%);
                        @include transition(background, $duration-100ms, ease-out, 0s);
                    }
                }

                &.active,
                &:hover:not(.no-css-hover) {

                    .icon {
                        color: $color-green-middle;

                        &:after {
                            background: $color-green-bright;
                        }
                    }
                }

                &.active {

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -22px;
                        left: -20px;
                        right: -20px;
                        height: 2px;
                        background: $color-green-middle;
                    }
                }
            }

            &:first-child:last-child {

                .wrapper {

                    &.active,
                    &:hover:not(.no-css-hover) {

                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    .choose-wrapper {
        display: none;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $color-blue-logo-2-opacity-010p;

        &.active {
            display: block;
        }
    }
}

@media screen and (max-width: 600px) {

    .mixin-choose {

        .choose-buttons {
            @include gap(40px);

            .clm {
                width: 100%;

                .wrapper,
                .wrapper.active {

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -20px;
                        left: -20px;
                        right: -20px;
                        height: 1px;
                        background: linear-gradient(90deg, $color-blue-logo-2-opacity-015p, $color-blue-logo-2-opacity-015p 50%, $transparent 0, $transparent);
                        background-size: 8px 1px;
                        pointer-events: none;
                    }

                }

                &:last-child {

                    .wrapper {

                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }

        &.choosen {

            .choose-buttons {
                @include gap(0);

                .clm {

                    .wrapper {

                        &:not(.active) {
                            display: none;
                        }

                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }
    }
}
