@use '../../basics/globals' as *;

#interfaces-overlay-user-password-set {
    width: 700px;

    form {

        p {
            padding-bottom: 10px;
        }
    }
}

@media screen and (max-width: 900px) {

    #interfaces-overlay-user-password-set {

        form {

            > .clm-wrapper {

                > .clm {

                    &.clm-7x4 {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
