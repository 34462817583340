@use '../../basics/globals' as *;

.mixin-query {

    &.mixin-query-pagination {

        .btn {
            display: inline-block;
            padding: 7px 10px;
            color: $color-grey-white;
            font-size: $font-size-16px;
            line-height: 1em;
            text-transform: uppercase;
            background: $color-blue-logo-2;
            border: 1px solid $transparent;
            cursor: pointer;
            @include font-family('Exo 2');
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            @include transition(all, 0.3s, ease-out, 0s);

            .icon {
                color: $color-grey-white;
            }

            &.prev {

                .icon {
                    left: -1px;
                }
            }

            &.next {

                .icon {
                    right: -1px;
                }
            }

            &.disabled {
                cursor: default;
                background: $color-blue-bright;
                border-color: $color-blue-bright;
            }
        }
    }
}
