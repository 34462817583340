@use '../../basics/globals' as *;

.mixin-newshub-article-topics {

    .clm-wrapper {
        display: block;
        column-count: 2;
        @include gap-i(60px);

        .clm {
            position: relative;
            margin-bottom: 40px;
            padding: 0 !important;
            max-width: 600px;

            .tile-wrapper {
                overflow: visible;

                .mixin-article {

                    > h4 {
                        margin: 20px 0 10px 0;
                    }

                    > ul {
                        display: flex;
                        flex-direction: column;
                        padding: 0 0 15px 20px;
                        list-style-type: disc;
                        @include gap(10px 0);

                        > li {
                            hyphens: auto;

                            a {
                                display: inline;
                                color: $color-blue-logo-1 !important;
                                font: inherit !important;
                                line-height: inherit !important;
                                hyphens: inherit;
                                cursor: pointer;
                                @include user-select(none);

                                &:hover:not(.no-css-hover) {
                                    text-decoration: underline;
                                }
                            }

                            em {
                                color: inherit;
                                font: inherit;
                                font-weight: 500;
                                hyphens: inherit;
                            }
                        }
                    }

                    .mixin-image-wrapper {
                        margin-bottom: 60px;
                        padding-top: 50%;
                        background-color: $color-blue-logo-1;

                        img {
                            position: absolute;
                            margin: 0;
                            width: 100%;
                        }
                    }

                    .mixin-article-header {
                        position: relative;

                        &:before {
                            content: attr(data-first-char);
                            position: absolute;
                            top: 3px;
                            left: -20px;
                            color: $color-blue-logo-2-opacity-010p;
                            font-size: 100px;
                            font-weight: 500;
                            text-transform: uppercase;
                            @include font-family('Museo Slab');
                        }
                    }
                }
            }

            &:first-child {
                margin-top: 100px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 1500px) {

    .mixin-newshub-article-footer {

        .mixin-share-links {
            display: flex;
            position: relative;
            bottom: 0;
            right: 0;
            @include gap(0 10px);
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-newshub-article-topics {

        .clm-wrapper {
            column-count: 1;

            .clm {
                padding: 0 !important;

                .tile-wrapper {

                    .mixin-article {

                        .mixin-article-header {

                            &:before {
                                left: -5px;
                            }
                        }
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}
