@use '../../basics/globals' as *;

.mixin-form-field-info {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 27px;
    text-align: center;
    @include border-radius($border-radius-3px, $border-radius-3px, 0, 0);

    .icon {
        top: 4px;
        color: $color-grey-white;
        font-size: 17px;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
    }

    &:before {
        bottom: -1px;
        left: -10px;
        width: 10px;
        height: 10px;
        @include border-radius(0, 0, $border-radius-3px, 0);
        @include opacity(0.75);
    }

    &:after {
        bottom: -3px;
        right: 0;
        width: 2px;
        height: 2px;
    }

    &.not-verified {
        background: $color-purple-middle;

        &:before {
            border-bottom: 1px solid $color-purple-middle;
            border-right: 1px solid $color-purple-middle;
        }

        &:after {
            border-top: 1px solid $color-purple-middle;
            border-right: 1px solid $color-purple-middle;
        }
    }

    &.not-valid {
        background: $color-red-error;

        &:before {
            border-bottom: 1px solid $color-red-error;
            border-right: 1px solid $color-red-error;
        }

        &:after {
            border-top: 1px solid $color-red-error;
            border-right: 1px solid $color-red-error;
        }
    }
}
