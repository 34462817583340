@use '../../basics/globals' as *;

body {

    > div[class^=privacypolicies-com]:first-child {
        background: rgba(0, 0, 0, 0.5);
        pointer-events: none;
        @include opacity(0);
        @include transition(opacity, 0.5s, ease-out, 0s);

        #privacypolicies-com---nb {
            top: auto;
            bottom: 20px;
            left: 20px;
            right: 20px;
            height: auto;
            max-width: 1200px;
            max-height: calc(100vh - 40px);
            margin: 0 auto;
            background: $color-white;
            @include border-radius($border-radius-30px, $border-radius-30px, $border-radius-30px, $border-radius-30px);

            button,
            button:focus {

                &.cc-nb-okagree,
                &.cc-nb-reject,
                &.cc-nb-changep {
                    float: right;
                    display: inline-block;
                    margin: 0 0 20px 20px !important;
                    padding: 10px 15px;
                    font-size: $font-size-16px;
                    font-weight: 300;
                    line-height: 1em;
                    text-align: center;
                    text-transform: uppercase;
                    box-shadow: none !important;
                    cursor: pointer;
                    @include font-family('Exo 2');
                    @include border-radius($border-radius-30px, $border-radius-30px, $border-radius-30px, $border-radius-30px);
                }

                &.cc-nb-okagree,
                &.cc-nb-reject {
                    color: $color-grey-white;
                    background: $color-green-middle;
                    border: 1px solid $color-green-middle;
                }

                &.cc-nb-changep {
                    color: $color-grey-bright;
                    background: $transparent;
                    border: 1px solid $color-grey-bright;
                }
            }

            .cc-nb-title {
                margin-bottom: 10px;
                color: $color-blue-logo-2;
                font-size: $font-size-24px;
                font-weight: 500;
                line-height: 1.3em;
                @include font-family('Museo Slab');
            }

            .cc-nb-text {
                @include font-family('Open Sans');
                color: $color-grey-dark;
                font-size: $font-size-16px;
                line-height: 1.5em;
            }

            .cc-nb-main-container {
                padding: 20px;
            }
        }

        &.show {
            pointer-events: auto;
            @include opacity(1);
        }
    }
}

#privacypolicies-com---preferences-center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    @include opacity(0);
    @include transition(opacity, 0.5s, ease-out, 0s);

    > div[class^=privacypolicies-com] {
        left: 20px;
        right: 20px;
        width: auto;
        max-width: 750px;
        margin: 0 auto;
        overflow: hidden;

        .cc-pc-container {
            max-height: 100%;
            overflow-y: auto;
            @include border-radius($border-radius-30px, $border-radius-30px, $border-radius-30px, $border-radius-30px);

            .cc-pc-head {
                display: block;
                position: relative;
                background: $color-white;
                border-color: $color-grey-bright;

                .cc-pc-head-title {
                    display: block;
                    padding: 0;

                    .cc-pc-head-title-headline {
                        padding: 60px 20px 20px 20px;
                        color: $color-blue-logo-2;
                        font-size: $font-size-30px;
                        font-weight: 500;
                        line-height: 1.3em;
                        @include font-family('Museo Slab');
                    }
                }

                .cc-pc-head-lang {
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    min-height: unset;
                    padding: 0;

                    .cc-pc-head-lang-select {
                        display: none;
                    }

                    .cc-pc-head-close {
                        position: relative;
                        width: 24px;
                        height: 24px;
                        margin: 0;
                        padding: 0;
                        font-size: 0;
                        border: 1px solid $transparent;
                        cursor: pointer;
                        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                        .trigger {
                            position: absolute;
                            top: -10px;
                            bottom: -10px;
                            left: -10px;
                            right: -10px;
                        }

                        &:before,
                        &:after {
                            content: '';
                            top: 10px;
                            left: 2px;
                            width: 18px;
                            height: 2px;
                            position: absolute;
                            background: $color-grey-dark;
                        }

                        &:before {
                            @include rotate-z(0, 45deg, center, center);
                        }

                        &:after {
                            @include rotate-z(0, -45deg, center, center);

                        }
                    }
                }
            }

            .cc-cp-body {

                .cc-cp-body-tabs {
                    width: 200px;
                    min-width: 200px;
                    background: $color-white;

                    .cc-cp-body-tabs-item {
                        position: relative;
                        border-color: $color-grey-light;
                        background: $color-white;

                        .cc-cp-body-tabs-item-link {
                            padding: 7px 15px 3px 20px;
                            color: $color-grey-dark;
                            font-weight: 300;
                            text-decoration: none;
                            text-transform: uppercase;
                            box-shadow: none;
                        }

                        &[active=true] {
                            background: $color-grey-light !important;

                            &:before {
                                content: '';
                                position: absolute;
                                top: -1px;
                                bottom: -1px;
                                left: 0;
                                z-index: 1;
                                width: 5px;
                                background: $color-blue-logo-2;
                            }
                        }
                    }
                }

                .cc-cp-body-content {
                    background: $color-grey-light !important;

                    .cc-cp-body-content-entry {
                        padding: 20px;

                        .cc-cp-body-content-entry-title {
                            color: $color-blue-logo-2;
                            font-weight: 600;
                            line-height: 1.3em;
                        }

                        .cc-cp-body-content-entry-text {
                            color: $color-grey-dark;
                            font-size: $font-size-14px;
                            font-weight: 300;
                            line-height: 1.6em;

                            a {
                                display: inline;
                                color: $color-blue-logo-1;
                                font: inherit;

                                &:hover {
                                    text-decoration: underline;
                                }

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }

                        .cc-custom-checkbox {
                            position: relative;

                            input[type=checkbox] {
                                display: none;
                            }

                            input[type=checkbox]+label:before {
                                border-color: $color-grey-bright;
                            }

                            input[type=checkbox]:checked+label:before {
                                background: $color-green-middle;
                            }

                            input[type=checkbox]+label:after {
                                border-color: $color-grey-bright;
                                background: $color-white;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }

            .cc-cp-foot {
                display: block;
                text-align: right;
                background: $color-white;
                border-color: $color-grey-bright;

                .cc-cp-foot-byline {
                    display: none !important;
                }

                .cc-cp-foot-button {
                    padding: 20px;

                    .cc-cp-foot-save {
                        display: inline-block;
                        margin: 0 !important;
                        padding: 10px 15px;
                        color: $color-grey-white;
                        font-size: $font-size-16px;
                        font-weight: 300;
                        line-height: 1em;
                        text-align: center;
                        text-transform: uppercase;
                        background: $color-green-middle;
                        border: 1px solid $color-green-middle;
                        box-shadow: none !important;
                        cursor: pointer;
                        @include opacity(1);
                        @include font-family('Exo 2');
                        @include border-radius(100px, 100px, 100px, 100px);
                    }
                }
            }
        }
    }

    &.show {
        pointer-events: auto;
        @include opacity(1);
    }
}

@media screen and (max-width: 700px) {

    body {

        > div[class^=privacypolicies-com]:first-child {

            #privacypolicies-com---nb {

                button,
                button:focus {

                    &.cc-nb-okagree,
                    &.cc-nb-reject,
                    &.cc-nb-changep {
                        float: none;
                        display: block;
                        width: 100%;
                        margin: 0 0 20px 0 !important;
                    }

                    &.cc-nb-changep {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    #privacypolicies-com---preferences-center {

        > div[class^=privacypolicies-com] .cc-pc-container .cc-cp-body .cc-cp-body-tabs {
            width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {

    #privacypolicies-com---preferences-center {

        > div[class^=privacypolicies-com] {

            .cc-pc-container .cc-cp-foot .cc-cp-foot-button .cc-cp-foot-save {
                display: block;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 400px) {

    body {

        > div[class^=privacypolicies-com]:first-child {
            display: flex;
            align-items: center;
            justify-content: center;

            #privacypolicies-com---nb {
                position: absolute;
                top: auto;
                bottom: auto;
                left: 10px;
                right: 10px;
                max-height: calc(100vh - 20px);
            }
        }
    }

    #privacypolicies-com---preferences-center {

        > div[class^=privacypolicies-com] {
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
        }
    }
}
