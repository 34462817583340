@use '../../basics/globals' as *;

.mixin-newshub-teaser-items {
    margin-top: 100px;
    column-count: 2;
    @include gap(100px);

    .newshub-item {
        display: inline-block;
        margin-bottom: 80px;
        max-width: 600px;
        @include flex(0 0 calc(50% - 50px));

        .tile-wrapper {
            overflow: visible;

            .mixin-article {

                .mixin-image-wrapper {
                    margin: -70px 0 40px 0;
                    padding-top: 50%;
                    background: $color-blue-logo-1;

                    img {
                        position: absolute;
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }

        &:first-child {
            margin-top: 100px;
        }
    }
}

@media screen and (max-width: 1400px) {

    .mixin-newshub-teaser-items {
        margin-bottom: -80px;
        @include gap(60px);
    }
}

@media screen and (max-width: 1000px) {

    .mixin-newshub-teaser-items {
        margin-top: 40px;
        column-count: 1;
    }
}

@media screen and (max-width: 600px) {

    .mixin-newshub-teaser-items {
        margin-top: 0;
    }
}
