@use '../../basics/globals' as *;

.mixin-form-number-pad {
    display: flex;
    flex-flow: row wrap;
    font-size: 0;
    @include column-count(3);
    @include gap(10px);

    span {
        line-height: 50px;
        @include flex(0 0 calc(33.333% - (10px * 2 / 3)));

        &.number {
            color: $color-grey-white;
            font-weight: 600;
            background: $color-blue-logo-2;
            text-align: center;
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
        }

        &.delete {
            text-align: center;

            .icon {
                color: $color-blue-logo-2;
                font-size: 32px;
            }
        }
    }
}
