@use '../../basics/globals' as *;

#interfaces-overlay-reports-orders {

    form {
        margin-top: 20px;
    }

    .table-wrapper {
        overflow-x: auto;

        table {
            min-width: 900px;
        }
    }
}
