@use '../basics/globals' as *;

#page-pricing-index {
    
    .mixin-article {

        .mixin-article-list {

            > li {

                > .headline {

                    h4 {
                        margin-top: 4px !important;
                        font-size: $font-size-14px !important;
                        font-weight: 600 !important;
                    }
                }

                > .content {
                    margin-top: -3px;

                    p {
                        font-size: $font-size-14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}
