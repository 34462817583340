@use '../../basics/globals' as *;

.mixin-multilingual {
    float: right;
    position: relative;
    top: 1px;
    margin: 0 0 3px 5px;
    font-size: 0;
    border: 1px solid $color-green-middle;
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

    span {
        display: inline-block;
        padding: 3px 5px;
        color: $color-green-middle;
        font-size: $font-size-12px;
        font-weight: 400;
        line-height: 1em;
        text-transform: uppercase;
    }
}

th {

    .mixin-multilingual {
        float: none;
        display: inline-block;
        margin-left: 10px;
    }
}
