@use '../../basics/globals' as *;

#footer {
    position: relative;
    padding: 60px 0 20px 0;
    background: $color-grey-shiny;

    .center-wide-ctn {
        position: relative;

        .navigation {

            nav {

                ul {

                    li {

                        a,
                        span {
                            @include transition(color, 0.3s, ease-out, 0s);

                            &.style-headline {
                                color: $color-blue-logo-2;
                                font-weight: 500;
                                text-transform: uppercase;
                                @include font-family('Exo 2');

                                &:hover:not(.no-css-hover) {
                                    text-decoration: underline;
                                }
                            }

                            &:hover:not(.no-css-hover) {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .logo {
                width: 100%;

                img {
                    max-width: 230px;
                }

                span {
                    display: block;
                    margin-top: 10px;
                    font-size: $font-size-16px;
                    @include font-family('Exo 2');
                }
            }
        }

        .copyright {
            margin: 20px 0 0 0;
            padding: 20px 0;
            text-align: center;

            p {
                font-size: $font-size-16px;
                @include font-family('Exo 2');
            }
        }

        .clm-wrapper {
            display: flex;
            @include flex-wrap(wrap);

            .clm {
                position: relative;

                &.clm-2,
                &.clm-4 {
                    padding: 0 20px;

                    &.clm-2 {
                        @include flex(1 1 50%);
                    }

                    &.clm-4 {
                        @include flex(1 1 25%);
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 10px;
        background: $color-green-middle;
    }
}

@media screen and (max-width: 1100px) {

    body {

        &.sidebar-badges-is-visible {

            #footer {
                padding-bottom: 70px;
            }
        }
    }
}

@media screen and (max-width: 800px) {

    #footer {

        .center-wide-ctn {

            .copyright {
                margin: 10px 0 0 0;
            }

            .clm-wrapper {

                .clm {
                    @include flex-i(1 1 100%);

                    &:nth-child(1) {
                        display: block;
                        padding: 0 0 20px 0;
                        text-align: center;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: -100vw;
                            width: 200vw;
                            height: 1px;
                            background: $color-blue-logo-2-opacity-010p;
                            pointer-events: none;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        display: block;
                        padding: 0;
                        text-align: center;

                        ul {

                            li {

                                &:first-child {
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
