@use '../../basics/globals' as *;

#interface-header {
    display: flex;
    height: 50px;
    max-width: 100%;
    overflow: hidden;
    background: $color-blue-black;
    @include flex(0 0 50px);

    h1 {
        padding: 0 15px;
        color: $color-grey-white;
        font-size: $font-size-18px;
        font-weight: 600;
        line-height: 50px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include flex(0 1 auto);
        @include font-family('Open Sans');
    }

    .user {
        display: flex;
        padding: 0 15px;
        margin-left: auto;
        max-width: 100%;
        font-size: 0;
        justify-content: right;
        @include gap(20px);
        @include flex(1 0 auto);

        > span {
            display: flex;
            color: $color-grey-white;
            font-size: $font-size-14px;
            line-height: 50px;
            @include gap(0 7px);
            @include flex(0 0 auto);

            &.number {
                margin: 10px 0;
                padding: 7px;
                height: 30px;
                font-weight: 600;
                line-height: 1.2em;
                background: $color-green-middle;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            }

            &.name {
                max-width: 200px;
                font-weight: 600;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-decoration: underline;
                @include flex(0 1 auto);
            }

            &.login,
            &.logout {
                margin: 10px 0;
                padding: 7px;
                height: 30px;
                font-weight: 600;
                line-height: 1.2em;
                white-space: nowrap;
                background: $color-blue-logo-2;
                cursor: pointer;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                .icon {
                    top: -1px;
                    color: inherit;
                }
            }

            &.icon {
                margin: 10px 0;
                padding: 5px 7px;
                height: 30px;
                font-size: 16px;
                line-height: 20px;
                background: $color-blue-logo-2;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #interface-header {

        h1 {
            display: none;
        }
    }
}
