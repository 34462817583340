@use '../../basics/globals' as *;

.mixin-form-header {
    display: flex;
    margin-bottom: 10px;
    @include flex-wrap(wrap);

    h4 {
        font-size: $font-size-22px;
        font-weight: 500;
        line-height: 1.3em;
    }

    span {
        display: inline-block;
        margin-left: 8px;
        color: $color-blue-bright;
        font-size: $font-size-12px;
        vertical-align: baseline;
    }

    p {
        width: 100%;
        margin: 10px 0;
        color: $color-blue-grey;
        font-size: $font-size-16px;
    }
}
