@use '../../basics/globals' as *;

.mixin-flash {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    min-height: 47px;
    background: $color-grey-white;
    border: 1px solid $color-grey-dark;
    border-left-width: 5px;
    overflow: hidden;
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

    p {
        display: block;
        // padding-right: 60px;
        font-size: $font-size-16px;
        font-weight: 400;
        line-height: 1.5;
        hyphens: auto;

        > a {
            display: inline !important;
            color: inherit !important;
            font: inherit !important;
            text-decoration: underline;
        }
    }

    .icon {
        float: right;
        min-width: 45px;
        margin: -10px -10px -10px 10px;
        padding: 10px 10px 10px 12px;
        color: $color-grey-white;
        font-size: 30px;
        line-height: 25px;
        @include border-radius($border-radius-3px, 0, $border-radius-3px, $border-radius-3px);
    }

    &.success {
        border-color: $color-green-middle;

        p {
            color: $color-green-middle;
        }

        .icon {
            padding-left: 17px;
            padding-right: 13px;
            font-size: 22px;
            background: $color-green-middle;
        }
    }

    &.error {
        border-color: $color-red-error;

        p {
            color: $color-red-error;
        }

        .icon {
            background: $color-red-error;
        }
    }

    &.notification {
        border-color: $color-blue-logo-2;

        p {
            color: $color-blue-logo-2;
        }

        .icon {
            background: $color-blue-logo-2;
        }
    }
}
