@use '../../basics/globals' as *;

@mixin mixin-form-code-padding($length) {
    padding-left: calc(100% / $length / 2 - 0.5ch);
    padding-right: calc(100% / $length / 2 - 0.5ch);
}

@mixin mixin-form-code-padding-input($length) {
    padding-left: calc(100% / $length / 2 - 0.6ch);
    padding-right: calc(100% / $length / 2 - 0.5ch);
}

@mixin mixin-form-code-gradient($length) {
    background: repeating-linear-gradient(
        to right,
        $transparent,
        $transparent calc(100% / $length),
        $color-blue-bright 1px,
        $color-blue-bright calc(calc(100% / $length) + 1px)
    );
}

.mixin-form-code {
    position: relative;

    input[type=text],
    input[type=password] {
        color: $transparent;
        caret-color: $color-grey-middle;
        font-variant: tabular-nums;
    }

    .code {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 34px;
        color: $color-grey-dark;
        font-size: $font-size-16px;
        font-variant: tabular-nums;
        line-height: 34px;
        overflow: hidden;
        pointer-events: none;
    }

    &[data-length='3'] {

        input[type=text],
        input[type=password] {
            @include mixin-form-code-padding-input(3);
            @include mixin-form-code-gradient(3);
        }

        .code {
            @include mixin-form-code-padding(3);
        }
    }

    &[data-length='4'] {

        input[type=text],
        input[type=password] {
            @include mixin-form-code-padding-input(4);
            @include mixin-form-code-gradient(4);
        }

        .code {
            @include mixin-form-code-padding(4);
        }
    }

    &[data-length='6'] {

        input[type=text],
        input[type=password] {
            @include mixin-form-code-padding-input(6);
            @include mixin-form-code-gradient(6);
        }

        .code {
            @include mixin-form-code-padding(6);
        }
    }

    &.type-password {

        input[type=password] {
            font-family: monospace;
        }

        .code {
            font-family: monospace;
        }
    }
}
