@use '../basics/globals' as *;

.mixin-toggle-password {
    float: right;
    position: relative;
    z-index: 10;
    top: -34px;
    width: 50px;
    height: 34px;
    margin-bottom: -34px;
    padding: 0 10px;
    line-height: 34px;
    text-align: right;

    .icon {
        color: $color-blue-middle;
        font-size: 20px;
        line-height: inherit;
    }
}
