@use 'globals' as *;

/* == RESET ====================================================== */

* {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    color: $color-black;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    font-size: $font-size-16px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.6em;
    vertical-align: top;
    background: transparent;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    height: 100%;
}

body {
    font-family: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
    min-height: 100%;
    position: relative;
}

address,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

picture {
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
    }
}

img {
    display: inline-block;
    font-size: 0;
    width: auto;
    height: auto;
}

ul {
    font-size: 0;
    list-style-type: none;
}

a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
}

a:active,
a:hover,
a:focus,
img:focus {
    border: none;
    outline: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    background: #000;
}

em {
    font: inherit;
    color: inherit;
}

form {
    display: block;
}

input,
select {
    vertical-align: middle;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

textarea {
    resize: none;
    overflow: auto;
    vertical-align: top;
    border: 1px solid $color-black;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type='radio'] {
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
}

input[type='checkbox'] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
}

input[type=text],
input[type=number],
input[type=password] {
    border: 1px solid $color-black;
}

audio,
canvas,
video {
    display: block;
}

video {
    object-fit: cover;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

b,
strong {
    font-weight: bold;
}

svg:not(:root) {
    overflow: hidden;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
}

input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
}

input[type='search'] {
    -webkit-appearance: textfield; /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box; /* 2 */
    box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
