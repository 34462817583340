@use '../../basics/globals' as *;

#interface-dashboard-reports {
    
    form {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-blue-logo-2-opacity-005p;

        .flex-wrapper {
            display: flex;
            @include flex-wrap(wrap);
            @include gap(20px);

            .flex-item {
                overflow: hidden;
                @include flex(1 1 auto);

                select {
                    min-width: 230px;
                }

                .mixin-btn {
                    padding-top: 10px;

                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}
