@use '../../basics/globals' as *;

#header {
    position: relative;
    z-index: 50;
    @include transition(background, 0.3s, $ease-in-out-cubic, 0.5s);

    .fixed-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 66px;
            background: $color-grey-white;
            @include opacity(0);
            @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.1));
            @include transition(all, 0.5s, $ease-in-out-cubic, 0s);
        }
    }

    .wrapper-navigation {
        padding: 20px 0;

        &.wrapper-navigation-top {

            nav {
                position: relative;
                float: right;

                > ul {
                    font-size: 0;

                    > li {
                        position: relative;
                        z-index: 1;
                        display: inline-block;
                        margin-left: 20px;
                        padding-bottom: 14px;

                        > a,
                        > span {
                            display: inline-block;
                            height: 32px;
                            padding: 0 15px;
                            color: $color-blue-logo-2;
                            font-size: $font-size-18px;
                            font-weight: 400;
                            line-height: 28px;
                            border: 1px solid $transparent;
                            @include user-select(none);
                            @include font-family('Exo 2');
                            @include border-radius(100px, 100px, 100px, 100px);

                            .label {
                                color: inherit;
                                font: inherit;
                            }

                            .icon {
                                margin: -1px -1px -1px 5px;
                                padding: 7px 10px 7px 7px;
                                color: $color-grey-white;
                                background: $color-blue-dark;
                                @include border-radius(0, 100px, 100px, 0);
                            }

                            &.has-icon {
                                padding-right: 0;
                                color: $color-blue-dark;
                                border: 1px solid $color-blue-dark;

                                &.active,
                                &:hover:not(.no-css-hover) {
                                    background: $color-blue-dark;
                                }
                            }

                            &.active,
                            &:hover:not(.no-css-hover) {
                                color: $color-grey-white;
                                background: $color-blue-dark;
                            }
                        }

                        > ul {
                            position: absolute;
                            top: 100%;
                            right: 50%;
                            padding: 20px;
                            background: $color-grey-white;
                            pointer-events: none;
                            @include opacity(0);
                            @include column-gap(40px);
                            @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.2));
                            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                            @include transform-origin(300px, 0);
                            @include transform(perspective(300px) translate(50%, 0) rotateX(-7deg));
                            @include transition(all, 0.4s, $ease-in-out-cubic, 0s);

                            > li {
                                display: inline-block;
                                width: 100%;
                                font-size: 0;
                                clear: both;
                                border-bottom: 1px solid $color-blue-logo-2-opacity-010p;
                                @include user-select(none);

                                a {
                                    display: block;
                                    position: relative;
                                    padding: 5px 0;
                                    font-size: 0;

                                    span {
                                        display: block;
                                        @include transition(color, 0.3s, ease-out, 0s);

                                        &.image-wrapper {
                                            display: flex;
                                            align-items: center;
                                            position: absolute;
                                            left: 0;
                                            height: calc(100% - 10px);
                                            padding: 0 10px;
                                            background: $color-blue-logo-2-opacity-005p;
                                            @include border-radius($border-radius-5px, $border-radius-5px, $border-radius-5px, $border-radius-5px);

                                            img {
                                                height: 20px;
                                            }
                                        }

                                        &.label {
                                            color: $color-blue-dark;
                                            font-size: $font-size-16px;
                                            font-weight: 600;
                                            text-transform: uppercase;
                                            white-space: nowrap;
                                        }

                                        &.teaser {
                                            width: 300px;
                                            color: $color-blue-grey;
                                            font-size: $font-size-14px;
                                            font-weight: 500;
                                            line-height: 1.4em;
                                            hyphens: auto;
                                        }
                                    }

                                    &:has(.image-wrapper) {
                                        padding-left: 50px;
                                    }

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        top: -1px;
                                        bottom: -1px;
                                        left: -20px;
                                        width: 5px;
                                        background: $color-green-middle;
                                        @include opacity(0);
                                        @include transition(opacity, 0.3s, ease-out, 0s);
                                    }

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        top: -1px;
                                        bottom: -1px;
                                        left: -20px;
                                        right: -20px;
                                        z-index: -1;
                                        background: $color-blue-logo-2-opacity-005p;
                                        @include opacity(0);
                                        @include transition(opacity, 0.3s, ease-out, 0s);
                                    }

                                    &.active,
                                    &:hover:not(.no-css-hover) {

                                        span {

                                            &.image-wrapper {
                                                background: $transparent;
                                                @include transition(background, 0.3s, ease-out, 0s);
                                            }
                                        }

                                        &:before,
                                        &:after {
                                            @include opacity(1);
                                        }
                                    }

                                    &:hover:not(.no-css-hover) {

                                        span {

                                            &.teaser {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                bottom: 100%;
                                right: 50%;
                                border-left: 10px solid $transparent;
                                border-right: 10px solid $transparent;
                                border-bottom: 10px solid $color-grey-white;
                                @include transform(translate(50%, 0));
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                height: 10px;
                                background: $color-blue-dark;
                                @include border-radius(0, 0, $border-radius-3px, $border-radius-3px);
                            }
                        }

                        .toggle-submenu {
                            display: none;
                            position: absolute;
                            top: -6px;
                            right: -13px;
                            width: 40px;
                            height: 40px;
                            cursor: pointer;
                            @include transition(all, 0.2s, ease-out, 0s);

                            &.opened {
                                top: -8px;
                                @include rotate-z(0, -90deg, 50%, 50%);
                            }

                            &:before,
                            &:after {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 2px;
                                height: 10px;
                                background: $color-grey-dark;
                            }

                            &:before {
                                top: 12px;
                                left: 22px;
                                @include rotate-z(0, 45deg, 0, 0);
                            }

                            &:after {
                                top: 19px;
                                left: 15px;
                                @include rotate-z(0, -45deg, 0, 0);
                            }
                        }

                        &:hover:not(.no-css-hover) {

                            > a {
                                color: $color-grey-white;
                                background: $color-blue-dark;
                            }

                            > ul {
                                pointer-events: auto;
                                @include opacity(1);
                                @include transform(perspective(300px) translate(50%, 0) rotateX(0deg));
                            }
                        }

                        &:last-child {

                            > ul {
                                right: -20px;
                                @include transform(perspective(300px) translate(0, 0) rotateX(-7deg));

                                &:before {
                                    right: 100px;
                                }
                            }

                            &:hover:not(.no-css-hover) {

                                > ul {
                                    @include transform(perspective(300px) translate(0, 0) rotateX(0deg));
                                }
                            }
                        }
                    }
                }
            }

            .logo {
                display: inline-block;
                overflow: hidden;

                img {
                    width: 200px;
                    max-height: 26px;
                }
            }
        }

        &.wrapper-navigation-bottom {
            padding-bottom: 80px;
            height: 68px;

            nav {

                ul {
                    display: flex;
                    @include flex-wrap(wrap);
                    @include gap(0 50px);

                    li {
                        display: inline-block;

                        a {
                            color: $color-blue-logo-1;
                            font-size: $font-size-18px;
                            font-weight: 400;
                            text-transform: uppercase;
                            @include user-select(none);
                            @include font-family('Exo 2');

                            span {
                                position: relative;
                                top: 3px;
                                margin-left: 5px;
                                color: $color-blue-logo-2;
                                font-size: $font-size-12px;
                                font-weight: 600;
                            }

                            &.active,
                            &:hover:not(.no-css-hover) {
                                color: $color-blue-logo-2;
                            }
                        }
                    }
                }
            }
        }
    }

    .wrapper-language {
        position: absolute;
        top: 0;
        right: 40px;
        font-size: 0;

        nav {
            padding-top: 27px;
            font-size: 0;
            white-space: nowrap;

            ul {
                font-size: 0;

                li {
                    display: inline-block;
                    margin-left: 7px;
                    font-size: 0;

                    a {
                        color: $color-blue-bright;
                        font-size: $font-size-14px;
                        font-weight: 500;
                        line-height: 1em;
                        text-transform: uppercase;
                        @include font-family('Exo 2');
                    }

                    &.active {

                        a {
                            color: $color-grey-dark;
                        }
                    }

                    &:first-child {
                        margin: 0;
                    }
                }
            }
        }
    }

    .wrapper-burger {
        display: none;
        position: absolute;
        top: 17px;
        right: 30px;
        z-index: 2;
        width: 40px;
        height: 40px;
        padding: 10px;
        cursor: pointer;
        @include transition(opacity, 0.3s, $ease-in-out-cubic, 0.8s);

        div {
            position: relative;
            margin-bottom: 3px;
            width: 100%;
            height: 2px;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: $color-grey-dark;
                @include transition(all, 0.3s, $ease-in-out-cubic, 0s);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-child(2) {

                &:after {
                    width: 10px;
                }
            }

            &:nth-child(3) {

                &:after {
                    width: 15px;
                }
            }

            &:nth-child(4) {

                &:after {
                    width: 5px;
                }
            }
        }

        &:hover:not(.no-css-hover) {

            div {

                &:after {
                    width: 100%;
                }
            }
        }
    }

    > .center-wide-ctn {
        position: relative;
        margin-top: 66px;

        .wrapper-language {
            display: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -100vw;
            width: 200vw;
            height: 1px;
            background: linear-gradient(90deg, $color-blue-logo-2-opacity-015p, $color-blue-logo-2-opacity-015p 50%, $transparent 0, $transparent);
            background-size: 8px 1px;
            pointer-events: none;
        }
    }
}

body:not(.window-scroll-top-0),
body[id=page-landing-index],
body[id=page-features-index],
body[id=page-pricing-index],
body[id^=page-solutions],
body[id^=page-newshub] {

    #header {

        .fixed-header {

            &:after {
                @include opacity(1);
            }
        }
    }
}

body:not(.css-loaded) {

    #header {

        .wrapper-navigation {

            &.wrapper-navigation-top {

                nav {

                    > ul {

                        > li {

                            > ul {
                                @include transition(all, 0s, linear, 0s);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {

    #header {

        .wrapper-language {
            display: none;
        }

        > .center-wide-ctn {

            .wrapper-language {
                display: block;
                right: 10px;
            }
        }
    }
}

@media screen and (max-width: 1500px) and (min-width: 1001px) {

    body.hero-is-initialized {

        #header {

            .wrapper-language {

                nav {

                    ul {

                        li {

                            a {
                                color: $color-grey-white;
                                @include opacity(0.5);
                            }

                            &.active {

                                a {
                                    @include opacity(1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {

    #header {

        .center-wide-ctn {

            .wrapper-navigation {

                &.wrapper-navigation-bottom {

                    nav {

                        ul {
                            @include gap(0 40px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px), screen and (pointer: coarse) {

    #header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $header-mobile-height;
        overflow: hidden;
        scrollbar-color: $color-blue-bright $color-grey-white;
        @include transition(all, 0.5s, $ease-in-out-cubic, 0s);

        .fixed-header {

            .wrapper-language {
                display: block;
                left: 40px;
            }

            &:after {
                @include opacity(1);
            }
        }

        .wrapper-navigation {

            &.wrapper-navigation-top {
                height: 66px;
                overflow: hidden;

                nav {
                    float: none;
                    position: relative;
                    margin-top: 20px;
                    padding-top: 20px;

                    > ul {
                        pointer-events: none;
                        @include opacity(0);
                        @include transition(opacity, 0.5s, $ease-in-out-cubic, 0s);

                        > li {
                            display: block;
                            position: relative;
                            margin-left: 0;
                            padding-bottom: 3px;
                            text-align: center;

                            > a,
                            > span {
                                font-weight: 600;
                                text-transform: uppercase;
                            }

                            > ul {
                                display: none;
                                position: relative;
                                top: auto;
                                right: 50% !important;
                                margin: 15px 0 20px 0;
                                pointer-events: auto;
                                @include opacity(1);
                                @include column-count(1);
                                @include transition(all, 0s, linear, 0s);
                                @include transform-i(perspective(300px) translate(50%, 0) rotateX(0deg));

                                > li {

                                    a {

                                        span {

                                            &.teaser {
                                                width: auto;
                                            }
                                        }

                                        &:has(.image-wrapper) {
                                            text-align: left;
                                        }
                                    }

                                    &:first-child {

                                        a {

                                            &.active,
                                            &:hover:not(.no-css-hover) {

                                                span {

                                                    &.label {

                                                        &:before {
                                                            right: 50%;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &:before {
                                    right: 50% !important;
                                }
                            }

                            .toggle-submenu {
                                display: block;
                            }

                            &:last-child {

                                > ul {
                                    margin-bottom: 10px;
                                }
                            }

                            &:hover:not(.no-css-hover) {

                                > ul {
                                    @include transform(perspective(300px) translate(50%, 0) rotateX(0deg));
                                }
                            }
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: -100vw;
                        width: 200vw;
                        height: 1px;
                        background: linear-gradient(90deg, $color-blue-logo-2-opacity-010p, $color-blue-logo-2-opacity-010p 50%, $transparent 0, $transparent);
                        background-size: 8px 1px;
                        pointer-events: none;
                        @include opacity(0);
                        @include transition(opacity, 0.5s, $ease-in-out-cubic, 0s);
                    }
                }

                .logo {
                    position: relative;
                    left: 50%;
                    @include transform(translate(-50%, 0));
                }
            }

            &.wrapper-navigation-bottom {
                padding: 0;
                height: auto;

                nav {
                    position: relative;

                    ul {
                        pointer-events: none;
                        @include opacity(0);
                        @include transition(opacity, 0.5s, $ease-in-out-cubic, 0s);

                        li {
                            text-align: center;
                            @include flex(1 0 100%);

                            a {
                                position: relative;

                                span {
                                    position: absolute;
                                }
                            }
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        top: -20px;
                        left: -100vw;
                        width: 200vw;
                        height: 1px;
                        background: linear-gradient(90deg, $color-blue-logo-2-opacity-010p, $color-blue-logo-2-opacity-010p 50%, $transparent 0, $transparent);
                        background-size: 8px 1px;
                        pointer-events: none;
                    }
                }
            }
        }

        .wrapper-language {
            left: 0;
            z-index: 1;
            right: auto;
        }

        .wrapper-burger {
            display: block;
        }

        > .center-wide-ctn {
            margin-top: 0;

            .wrapper-language {
                display: none;
            }

            &:after {
                content: none;
            }
        }

        &.show-mobile-navigation {
            position: fixed;
            height: 100%;
            overflow-y: auto;
            background: $color-grey-white;
            @include smooth-scroll();
            @include transition(height, 0s, linear, 0s);

            .fixed-header {
                position: relative;

                &:after {
                    @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0));
                }
            }

            .wrapper-navigation {

                &.wrapper-navigation-top {
                    height: auto;

                    nav {

                        > ul {
                            pointer-events: auto;
                            @include opacity(1);
                            @include transition(opacity, 0.5s, $ease-in-out-cubic, 0.3s);
                        }

                        &:before {
                            @include opacity-i(1);
                        }
                    }
                }

                &.wrapper-navigation-bottom {

                    nav {

                        > ul {
                            pointer-events: auto;
                            @include opacity(1);
                            @include transition(opacity, 0.5s, $ease-in-out-cubic, 0.3s);
                        }
                    }
                }
            }

            .wrapper-burger {

                div {

                    &:nth-child(1) {

                        &:after {
                            @include rotate-z(0, -45deg, 100%, 0);
                        }
                    }

                    &:nth-child(2) {

                        &:after {
                            width: 0;
                        }
                    }

                    &:nth-child(3) {

                        &:after {
                            width: 0;
                        }
                    }

                    &:nth-child(4) {

                        &:after {
                            top: -1px;
                            width: 100%;
                            @include rotate-z(0, 45deg, 100%, 100%);
                        }
                    }
                }
            }

            &:after {
                @include opacity(1);
                @include transition(opacity, 0.3s, $ease-in-out-cubic, 0s);
            }
        }

        &.closing-mobile-navigation {
            position: fixed;
            height: 100%;

            .fixed-header {
                position: relative;

                &:after {
                    @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0));
                }
            }

            .wrapper-navigation {

                &.wrapper-navigation-top {
                    height: auto;
                }
            }
        }
    }

    body {

        &:not(.window-scroll-top-0) {

            #header {

                .wrapper-navigation {

                    &.wrapper-navigation-top {

                        nav {

                            &:before {
                                @include opacity(0);
                            }
                        }
                    }
                }
            }
        }

        &.sidebar-badges-is-visible {

            #header {

                &.show-mobile-navigation {
                    height: calc(100% - 52px);
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {

    #header {

        .wrapper-language {
            left: 20px !important;
        }

        .wrapper-burger {
            right: 10px;
        }
    }
}

@media screen and (max-width: 400px) {

    #header {

        .wrapper-navigation {

            &.wrapper-navigation-top {

                .logo {
                    top: 4px;

                    img {
                        width: 180px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {

    #header {

        .wrapper-navigation {

            &.wrapper-navigation-top {

                .logo {
                    top: 6px;

                    img {
                        width: 140px;
                    }
                }
            }
        }
    }
}
