@use '../../basics/globals' as *;

.mixin-switcher {
    border: 1px solid $color-blue-bright;

    span {
        position: relative;
        min-width: 40px;
        background: $color-blue-bright;
        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

        &.active {
            color: $color-grey-dark;
            text-transform: lowercase;
            @include border-radius(0, 0, 0, 0);

            &:first-of-type {
                color: $color-grey-light;
                background: $color-green-middle;
            }

            &:after {
                content: none;
            }
        }

        &:after {
            content: '|||';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: $color-grey-white;
            font-size: 8px;
            line-height: 14px;
            text-align: center;
        }
    }
}
