@use '../../basics/globals' as *;

.mixin-newshub-article-navigation {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    @include gap(0 10px);

    a {
        display: inline-block;
        position: relative;
        top: -2px;
        padding: 0 10px;
        line-height: 1em;
        text-align: center;
        @include user-select(none);

        .icon {
            color: $color-blue-logo-1;
        }
    }

    > span {
        display: inline-block;
        color: $color-blue-bright;
        font-size: $font-size-16px;
        line-height: 1em;
        @include user-select(none);
        @include font-family('Museo Slab');

        &.number {
            width: 20px;
        }

        &.separator {
            position: relative;
            left: 1px;
        }
    }
}
