@use '../../basics/globals' as *;

#interfaces-overlay-area-table {
    width: 400px;

    > ul {

        > li {
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid $color-blue-logo-2-opacity-005p;

            > ul {

                > li {
                    display: flex;
                    margin-bottom: 3px;
                    overflow: hidden;
                    @include gap(0 10px);

                    > span {
                        font-size: $font-size-14px;
                        font-weight: 400;
                        overflow: hidden;

                        &.label {
                            @include flex(1 1 auto);
                        }

                        &.text {
                            text-align: right;
                            @include flex(0 0 auto);

                            .icon {
                                color: $color-blue-logo-2;
                            }
                        }

                        &.box {
                            padding: 4px 7px;
                            color: $color-grey-light;
                            line-height: 1em;
                            background: $color-blue-logo-2;
                            text-transform: uppercase;
                            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                            .icon {
                                display: none;

                                &[class^='icon-'],
                                &[class*=' icon-'] {
                                    display: inline-block;
                                    margin-left: 9px;
                                    color: $color-grey-light;
                                    font-size: 14px;
                                }
                            }

                            &.highlight-green {
                                background: $color-green-middle;
                            }

                            &.highlight-purple {
                                background: $color-purple-middle;
                            }

                            &.highlight-orange {
                                background: $color-orange-middle;
                            }

                            &.highlight-red {
                                background: $color-red-error;
                            }
                        }

                        &.bold {
                            font-weight: 600;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &.discreet {
                    @include opacity(0.5);
                }
            }

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
