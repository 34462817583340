 @use '../../basics/globals' as *;

 .mixin-newshub-article-footer {

    .mixin-share-links {
        position: absolute;
        bottom: 90px;
        right: -60px;
        z-index: 1;
    }
 }
