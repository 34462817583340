@use '../../basics/globals' as *;

#accounts-overlay-register {
    width: 700px;


    .mixin-choose {

        .choose-buttons {

            .clm-wrapper {
                justify-content: center;
            }
        }
    }
}
