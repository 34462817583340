@use 'globals' as *;

/* == NATIVE - COMMON ====================================================== */

.clear-both {
    clear: both;
}

.clear-both-i {
    clear: both !important;
}

.cursor-pointer {
    cursor: pointer;
}

.white-space-nowrap {
    white-space: nowrap;
}

.white-space-nowrap-i {
    white-space: nowrap !important;
}

.white-space-normal {
    white-space: normal;
}

.white-space-normal-i {
    white-space: normal !important;
}

.visibility-hidden {
    visibility: hidden;
}

.word-break-break-all {
    word-break: break-all;
}

.list-style-position-inside {
    list-style-position: inside;
}

.list-style-type-decimal-leading-zero {
    list-style-type: decimal-leading-zero;
}

.gap-0-10px {
    @include gap(0 10px);
}

.column-count-2 {
    @include column-count(2);
};

/* == NATIVE - DISPLAY ====================================================== */

.display-none {
    display: none;
}

.display-none-i {
    display: none !important;
}

.display-block {
    display: block;
}

.display-block-i {
    display: block !important;
}

.display-inline {
    display: inline;
}

.display-inline-i {
    display: inline !important;
}

.display-inline-block {
    display: inline-block;
}

.display-inline-block-i {
    display: inline-block !important;
}

/* == NATIVE - FLEX ====================================================== */

.flex-0-0-auto {
    @include flex(0 0 auto);
}

.flex-0-0-auto-i {
    @include flex-i(0 0 auto);
}

.flex-1-0-auto {
    @include flex(1 0 auto);
}

.flex-1-0-auto-i {
    @include flex-i(1 0 auto);
}

.flex-0-1-auto {
    @include flex(0 1 auto);
}

.flex-0-1-auto-i {
    @include flex-i(0 1 auto);
}

.flex-1-1-auto {
    @include flex(1 1 auto);
}

.flex-1-1-auto-i {
    @include flex-i(1 1 auto);
}

.flex-0-0-100p {
    @include flex(0 0 100%);
}

.flex-0-0-100p-i {
    @include flex-i(0 0 100%);
}

.display-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-right {
    justify-content: right;
}

.justify-content-right-i {
    justify-content: right !important;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-center-i {
    justify-content: center !important;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-between-i {
    justify-content: space-between !important;
}

/* == NATIVE - POSITION ====================================================== */

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

/* == NATIVE - Z-INDEX ====================================================== */

.z-index--1 {
    z-index: -1;
}

.z-index--1-i {
    z-index: -1 !important;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-2-i {
    z-index: 2 !important;
}

.z-index-3 {
    z-index: 3;
}

.z-index-3-i {
    z-index: 3 !important;
}

.z-index-10 {
    z-index: 10;
}

.z-index-10-i {
    z-index: 10 !important;
}

/* == NATIVE - FLOAT ====================================================== */

.float-left {
    float: left;
}

.float-left-i {
    float: left !important;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.float-none-i {
    float: none !important;
}

/* == NATIVE - TEXT ====================================================== */

.text-align-left {
    text-align: left;
}

.text-align-left-i {
    text-align: left !important;
}

.text-align-right {
    text-align: right;
}

.text-align-right-i {
    text-align: right !important;
}

.text-align-center {
    text-align: center;
}

.text-align-center-i {
    text-align: center !important;
}

.text-transform-lowercase {
    text-transform: lowercase;
}

.text-transform-lowercase-i {
    text-transform: lowercase !important;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text-transform-uppercase-i {
    text-transform: uppercase !important;
}

.text-decoration-underline {
    text-decoration: underline;
}

.text-transform-capitalize {
    text-transform: capitalize;
}

.text-decoration-none-i {
    text-decoration: none !important;
}

/* == NATIVE - VERTICAL-ALIGN ====================================================== */

.vertical-align-top {
    vertical-align: top;
}

.vertical-align-top-i {
    vertical-align: top !important;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.vertical-align-baseline {
    vertical-align: baseline;
}

.vertical-align-inherit {
    vertical-align: inherit;
}

/* == NATIVE - OVERFLOW ====================================================== */

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-scroll {
    overflow-y: scroll;
    @include smooth-scroll();
}

/* == NATIVE - BORDER ====================================================== */

.border-top-none {
    border-top: none;
}

.border-bottom-none {
    border-bottom: none;
}

.border-bottom-none-i {
    border-bottom: none !important;
}

.border-radius-3px {
    @include border-radius(3px, 3px, 3px, 3px);
}

.border-radius-5px {
    @include border-radius(5px, 5px, 5px, 5px);
}

.border-radius-10px {
    @include border-radius(10px, 10px, 10px, 10px);
}

.border-radius-20px {
    @include border-radius(20px, 20px, 20px, 20px);
}

.border-radius-30px {
    @include border-radius(30px, 30px, 30px, 30px);
}

/* == NATIVE VALUES - TOP ====================================================== */

.top-0 {
    top: 0;
}

.top-2px {
    top: 2px;
}

.top--2px {
    top: -2px;
}

.top--2px-i {
    top: -2px !important;
}

.top-3px {
    top: 3px;
}

.top-3px-i {
    top: 3px !important;
}

.top-4px {
    top: 4px;
}

.top-5px {
    top: 5px;
}

.top-8px {
    top: 8px;
}

.top-100 {
    top: 100%;
}

/* == NATIVE VALUES - BOTTOM ====================================================== */

.bottom-0 {
    bottom: 0;
}

.bottom-10px {
    bottom: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */

.left-auto-i {
    left: auto !important;
}

.left-0 {
    left: 0;
}

.left-10 {
    left: 10px;
}

/* == NATIVE VALUES - RIGHT ====================================================== */

.right-0 {
    right: 0;
}

.right-10px {
    right: 10px;
}

/* == NATIVE VALUES - WIDTH ====================================================== */

.width-20px {
    width: 20px;
}

.width-20px-i {
    width: 20px !important;
}

.width-33px {
    width: 33px;
}

.width-34px {
    width: 34px;
}

.width-50px {
    width: 50px;
}

.width-80px {
    width: 80px;
}

.width-100px {
    width: 100px;
}

.width-110px {
    width: 110px;
}

.width-120px {
    width: 120px;
}

.width-120px-i {
    width: 120px !important;
}

.width-140px {
    width: 140px;
}

.width-150px {
    width: 150px;
}

.width-160px {
    width: 160px;
}

.width-170px {
    width: 170px;
}

.width-200px {
    width: 200px;
}

.width-250px {
    width: 250px;
}

.width-280px {
    width: 280px;
}

.width-300px {
    width: 300px;
}

.width-330px {
    width: 330px;
}

.width-350px {
    width: 350px;
}

.width-400px {
    width: 400px;
}

.width-500px {
    width: 500px;
}

.width-600px {
    width: 600px;
}

.width-100p {
    width: 100%;
}

/* == NATIVE VALUES - MIN WIDTH ====================================================== */
.min-width-300px {
    min-width: 300px;
}

.min-width-400px {
    min-width: 400px;
}

.min-width-640px {
    min-width: 640px;
}

/* == NATIVE VALUES - MAX-WIDTH ====================================================== */

.max-width-100px {
    max-width: 100px;
}

.max-width-110px {
    max-width: 110px;
}

.max-width-130px {
    max-width: 130px;
}

.max-width-170px {
    max-width: 170px;
}

.max-width-200px {
    max-width: 200px;
}

.max-width-280px-i {
    max-width: 280px !important;
}

.max-width-600px {
    max-width: 600px;
}

/* == NATIVE VALUES - HEIGHT ====================================================== */

.height-auto-i {
    height: auto !important;
}

.height-1px {
    height: 1px;
}

.height-20px {
    height: 20px;
}

.height-26px {
    height: 26px;
}

.height-34px {
    height: 34px;
}

.height-100px {
    height: 100px;
}

.height-100px-i {
    height: 100px !important;
}

.height-195px {
    height: 195px;
}

.height-350px {
    height: 350px;
}

.height-050p {
    height: 50%;
}

.height-050p-i {
    height: 50% !important;
}

.height-100p {
    height: 100%;
}

.height-100p-i {
    height: 100% !important;
}

/* == NATIVE VALUES - MIN HEIGHT ====================================================== */

.min-height-40px {
    min-height: 40px;
}

.min-height-40px-i {
    min-height: 40px !important;
}

/* == NATIVE VALUES - MAX HEIGHT ====================================================== */

.max-height-100px {
    max-height: 100px;
}

/* == NATIVE VALUES - MARGIN ====================================================== */

.margin-3px {
    margin: 3px;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-0-i {
    margin-top: 0 !important;
}

.margin-top-2px {
    margin-top: 2px;
}

.margin-top-4px {
    margin-top: 4px;
}

.margin-top-5px {
    margin-top: 5px;
}

.margin-top-10px {
    margin-top: 10px;
}

.margin-top-10px-i {
    margin-top: 10px !important;
}

.margin-top-20px {
    margin-top: 20px;
}

.margin-top-20px-i {
    margin-top: 20px !important;
}

.margin-top-25px {
    margin-top: 25px;
}

.margin-top-25px-i {
    margin-top: 25px !important;
}

.margin-top-30px {
    margin-top: 30px;
}

.margin-top-30px-i {
    margin-top: 30px !important;
}

.margin-top-40px {
    margin-top: 40px;
}

.margin-top-40px-i {
    margin-top: 40px !important;
}

.margin-top-50px {
    margin-top: 50px;
}

.margin-top-50px-i {
    margin-top: 50px !important;
}

.margin-top-60px {
    margin-top: 60px;
}

.margin-top-80px {
    margin-top: 80px;
}

.margin-top--1px {
    margin-top: -1px;
}

.margin-top--20px {
    margin-top: -20px;
}

.margin-top--34px {
    margin-top: -34px;
}

.margin-top--060p {
    margin-top: -60%;
}

.margin-top--100p {
    margin-top: -100%;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-left-5px {
    margin-left: 5px;
}

.margin-left-10px {
    margin-left: 10px;
}

.margin-left-20px {
    margin-left: 20px;
}

.margin-left-25px {
    margin-left: 25px;
}

.margin-left-30px {
    margin-left: 30px;
}

.margin-left-310px {
    margin-left: 310px;
}

.margin-right-10px {
    margin-right: 10px;
}

.margin-right-20px {
    margin-right: 20px;
}

.margin-right-30px {
    margin-right: 30px;
}

.margin-right-40px {
    margin-right: 40px;
}

.margin-right-48px {
    margin-right: 48px;
}

.margin-right-60px {
    margin-right: 60px;
}

.margin-right--10px {
    margin-right: -10px;
}

.margin-right--20px {
    margin-right: -20px;
}

.margin-right--40px {
    margin-right: -40px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-0-i {
    margin-bottom: 0 !important;
}

.margin-bottom-10px {
    margin-bottom: 10px;
}

.margin-bottom-10px-i {
    margin-bottom: 10px !important;
}

.margin-bottom-20px {
    margin-bottom: 20px;
}

.margin-bottom-40px {
    margin-bottom: 40px;
}

.margin-bottom-40px-i {
    margin-bottom: 40px !important;
}

.margin-bottom-60px {
    margin-bottom: 60px;
}

.margin-bottom-60px-i {
    margin-bottom: 60px !important;
}

.margin-bottom-80px {
    margin-bottom: 80px;
}

.margin-bottom--10px {
    margin-bottom: -10px;
}

.margin-bottom--10px-i {
    margin-bottom: -10px !important;
}

.margin-bottom--50px {
    margin-bottom: -50px;
}

.margin-bottom--240px {
    margin-bottom: -240px;
}

.margin-vertical--20px {
    margin-left: -20px;
    margin-right: -20px;
}

/* == NATIVE VALUES - PADDING ====================================================== */

.adding-0 {
    padding: 0;
}

.padding-0-i {
    padding: 0 !important;
}

.padding-top-0 {
    padding-top: 0;
}

.padding-top-0-i {
    padding-top: 0 !important;
}

.padding-top-10px {
    padding-top: 10px;
}

.padding-top-20px {
    padding-top: 20px;
}

.padding-top-20px-i {
    padding-top: 20px !important;
}

.padding-top-25px {
    padding-top: 25px;
}

.padding-top-30px {
    padding-top: 30px;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.padding-bottom-0-i {
    padding-bottom: 0 !important;
}

.padding-bottom-5px {
    padding-bottom: 5px;
}

.padding-bottom-10px {
    padding-bottom: 10px;
}

.padding-bottom-20px {
    padding-bottom: 20px;
}

.padding-bottom-20px-i {
    padding-bottom: 20px !important;
}

.padding-bottom-25px {
    padding-bottom: 25px;
}

.padding-bottom-30px {
    padding-bottom: 30px;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-left-0-i {
    padding-left: 0 !important;
}

.padding-left-3px-i {
    padding-left: 3px !important;
}

.padding-left-5px {
    padding-left: 5px;
}

.padding-left-10px {
    padding-left: 10px;
}

.padding-left-20px {
    padding-left: 20px;
}

.padding-left-25px-i {
    padding-left: 25px !important;
}

.padding-left-150px {
    padding-left: 150px;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-right-0-i {
    padding-right: 0 !important;
}

.padding-right-3px {
    padding-right: 3px;
}

.padding-right-3px-i {
    padding-right: 3px !important;
}

.padding-right-5px {
    padding-right: 5px;
}

.padding-right-10px {
    padding-right: 10px;
}

.padding-right-20px {
    padding-right: 20px;
}

.padding-right-43px-i {
    padding-right: 43px !important;
}

.padding-horizontal-3px {
    padding-left: 3px;
    padding-right: 3px;
}

.padding-horizontal-4px {
    padding-left: 4px;
    padding-right: 4px;
}

.padding-horizontal-5px {
    padding-left: 5px;
    padding-right: 5px;
}

.padding-horizontal-20px {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-vertical-3px {
    padding-top: 3px;
    padding-bottom: 3px;
}

.padding-vertical-5px {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-vertical-20px {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-3px {
    padding: 3px;
}

.padding-10px {
    padding: 10px;
}

/* == NATIVE VALUES - FONT SIZE ====================================================== */

.font-size-0 {
    font-size: 0;
}

.font-size-1em {
    font-size: 1em;
}

.font-size-1c6em {
    font-size: 1.6em;
}

.font-size-12px {
    font-size: $font-size-12px;
}

.font-size-14px {
    font-size: $font-size-14px;
}

.font-size-18px {
    font-size: $font-size-18px;
}

.font-size-20px {
    font-size: $font-size-20px;
}

.font-size-22px {
    font-size: $font-size-22px;
}

.font-size-24px {
    font-size: $font-size-24px;
}

.font-size-26px {
    font-size: $font-size-26px;
}

.font-size-28px {
    font-size: $font-size-28px;
}

.font-size-30px {
    font-size: $font-size-30px;
}

.font-size-32px {
    font-size: $font-size-32px;
}

.font-size-34px {
    font-size: $font-size-34px;
}

.font-size-38px {
    font-size: $font-size-38px;
}

.font-size-40px {
    font-size: $font-size-40px;
}

.font-size-80px {
    font-size: $font-size-80px;
}

/* == NATIVE VALUES - FONT WEIGHT ====================================================== */

.font-weight-200 {
    font-weight: 200;
}

.font-weight-200-i {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-300-i {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-400-i {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-500-i {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-600-i {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-700-i {
    font-weight: 700 !important;
}

/* == NATIVE VALUES - LINE HEIGHT ====================================================== */

.line-height-1em {
    line-height: 1em;
}

.line-height-26px {
    line-height: 26px;
}

.line-height-34px {
    line-height: 34px;
}

.line-height-37px {
    line-height: 37px;
}

/* == OPACITY ====================================================== */

.opacity-0 {
    @include opacity(0);
}

.opacity-0-i {
    @include opacity-i(0);
}

.opacity-1-i {
    @include opacity-i(1);
}

/* == HOVER ====================================================== */

.hover-text-decoration-underline {

    &:hover:hover:not(.no-css-hover) {
        text-decoration: underline;
    }
}
