@use '../../basics/globals' as *;

.mixin-duplicate {
    position: relative;

    .duplicate-item {
        padding-right: 64px;

        .duplicate-remove {
            position: absolute;
            bottom: 0;
            right: 0;

            .mixin-btn {
                padding-top: 0;

                .btn {
                    padding: 7px 12px;
                }
            }
        }
    }

    .duplicate-add {
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
