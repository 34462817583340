@use '../../basics/globals' as *;

#clients-overlay-menu-configure-qr-codes {
    width: 350px;

    form {
        margin-top: 20px;

        [data-download] {
            display: none;
        }
    }
}
