@use '../../basics/globals' as *;

form {

    .row {
        position: relative;

        > a,
        .link-style {
            display: inline-block;
            padding: 0 15px 0 5px;
            color: $color-blue-logo-1;
            font-size: $font-size-12px;
            font-weight: 400;
            cursor: pointer;

            &.type-text-style {
                padding: $form-padding;
            }

            &:hover:not(.no-css-hover) {
                text-decoration: underline;
            }
        }

        > input + a,
        > input + .link-style,
        > .select2 + a,
        > .select2 + .link-style {
            padding-top: 3px;
        }

        .validation-info {
            position: absolute;
            bottom: 0;
            left: 50%;
        }

        .counter-wrapper {
            float: right;
            position: relative;
            top: 3px;
            right: 5px;
            color: $color-blue-bright;
        }

        .validation-status-wrapper {
            clear: left;
            margin: 5px 0 0 5px;

            > span {
                dislpay: block;

                span {
                    display: inline-block;
                    color: $color-blue-bright;

                    &.icon {
                        top: 2px;
                        margin-right: 5px;

                        &.icon-checkmark {
                            color: $color-green-middle;
                        }
                    }
                }
            }
        }

        .mixin-btn {
            justify-content: right;
        }

        &.row-border {

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(90deg, $color-blue-logo-2-opacity-015p, $color-blue-logo-2-opacity-015p 50%, $transparent 0, $transparent);
                background-size: 8px 1px;
                pointer-events: none;
            }

            &:last-child {
                padding-bottom: 0;

                &:after {
                    content: none;
                }
            }
        }

        &.not-verified {

            input[type=text],
            input[type=number],
            input[type=email],
            input[type=password],
            input[type=date],
            input[type=time],
            input[type=datetime-local],
            textarea,
            .input-checkbox,
            .input-radio,
            .type-text-style,
            .type-textarea-style {
                border-color: $color-purple-middle !important;

                &:focus {
                    @include box-shadow-inset(0, 0, 3px, 0, $color-purple-middle);
                }
            }

            .select2.select2-container,
            .select2.select2-container--open {

                .select2-selection.select2-selection--default,
                .select2-selection.select2-selection--single,
                .select2-selection.select2-selection--multiple,
                .select2-selection.select2-selection--default:focus,
                .select2-selection.select2-selection--single:focus,
                .select2-selection.select2-selection--multiple:focus {
                    border-color: $color-purple-middle !important;
                }
            }
        }

        &.not-valid {

            input[type=text],
            input[type=number],
            input[type=email],
            input[type=password],
            input[type=date],
            input[type=time],
            input[type=datetime-local],
            textarea,
            .input-checkbox,
            .input-radio,
            .type-text-style,
            .type-textarea-style {
                border-color: $color-red-error !important;

                &:focus {
                    @include box-shadow-inset(0, 0, 3px, 0, $color-red-error);
                }
            }

            .select2.select2-container,
            .select2.select2-container--open {

                .select2-selection.select2-selection--default,
                .select2-selection.select2-selection--single,
                .select2-selection.select2-selection--multiple,
                .select2-selection.select2-selection--default:focus,
                .select2-selection.select2-selection--single:focus,
                .select2-selection.select2-selection--multiple:focus {
                    border-color: $color-red-error !important;
                }
            }
        }

        &:last-child {
            margin-bottom: 10px;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    > .row {

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form-box {
        position: relative;
        z-index: 1;

        &.error-flag {
            border-color: $color-red-error !important;
        }
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    input[type=date],
    input[type=time],
    input[type=datetime-local],
    textarea,
    .input-checkbox,
    .input-radio,
    .input-switcher,
    .type-text-style,
    .type-textarea-style {
        color: $color-grey-dark;
        border-color: $color-blue-bright !important;
        background: $color-white !important;

        &.unchangeable {
            color: $color-blue-bright;
        }

        &.input-checkbox,
        &.input-radio {
            margin-top: 3px;

            label {
                margin-bottom: 0;
                font-size: $font-size-14px;
                font-weight: inherit;
                @include user-select(none);

                span:not(.uniform) {
                    top: 0;
                    display: block;
                    color: $color-blue-bright;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: 24px;
                    hyphens: auto;
                    @include transition(color, 0.3s, ease-out, 0s);

                    a {
                        display: inline;
                        color: $color-blue-logo-1;
                        hyphens: auto;
                    }
                }

                .uniform {
                    float: left;
                    top: 0;
                }
            }

            &.disabled {
                @include opacity(0.5);
            }

            &.input-checkbox-checked,
            &.input-radio-checked {

                label {

                    span:not(.uniform) {
                        color: $color-grey-dark;
                    }
                }
            }
        }

        &.input-switcher {

            .mixin-switcher {
                margin: 2px 4px 2px 8px;
            }

            > span {
                font-size: $font-size-12px;
                line-height: 24px;
            }
        }

        &.type-text-style,
        &.type-textarea-style {
            font-size: $font-size-16px;
            text-decoration: none;

            > .text {
                color: $color-blue-bright;
            }

            .icon {
                top: 5px;
                color: $color-blue-logo-2;
                font-size: 16px;

                &.icon-checkmark {
                    color: $color-green-middle;
                }

                &.icon-warning {
                    top: 4px;
                    font-size: 18px;
                }
            }

            &[href] {
                text-decoration: none !important;

                > .text {
                    color: $color-blue-logo-2;
                    text-decoration: underline;
                }

                .icon {
                    text-decoration: none !important;
                }
            }
        }

        &.type-code-style {
            background: $color-blue-logo-2-opacity-005p !important;
        }

        &:-webkit-autofill {
            @include text-fill-color($color-blue-bright);
        }

        &:focus {
            border-color: $color-blue-logo-1 !important;
            @include box-shadow-inset(0, 0, 3px, 0, $color-blue-logo-1);
        }
    }


    pre.type-text-style,
    pre.type-textarea-style {
        overflow: visible !important;

        .icon {
            position: absolute !important;
            top: -25px !important;
            right: 7px !important;
            cursor: pointer;
        }
    }

    .clm-wrapper {

        .clm {
            padding: 0 10px !important;

            .mixin-form-field-info {
                right: 10px;
            }

            &:first-child {
                padding-left: 0 !important;
            }

            &:last-child {
                padding-right: 0 !important;

                .mixin-form-field-info {
                    right: 0;
                }
            }
        }

        &.equal-distribution {
            margin: 0 -10px !important;

            > .clm {

                &:first-child {
                    padding-left: 10px !important;
                }

                &:last-child {
                    padding-right: 10px !important;
                }

                &.clm-2 {

                    &:nth-child(odd) {
                        padding-left: 10px;
                    }

                    &:nth-child(even) {
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    &.division-form {
        position: relative;
        z-index: 1;
        margin-top: 20px;

        .division-header {
            position: relative;
            margin-bottom: 0;
            font-size: 0;

            .mixin-btn {
                margin: 0 !important;
                padding: 0 25px !important;

                .btn {
                    position: relative;
                    width: 100%;
                    text-align: center;
                    color: $color-grey-white;
                    background: $color-blue-bright;
                    border-color: $color-blue-bright;
                    overflow: visible;
                    @include border-radius(0, 0, 0, 0);
                    @include user-select(none);
                    @include transition(all, 0s, ease-out, 0s);

                    &.active {
                        z-index: 1;
                        background: $color-blue-logo-2;
                        border-color: $color-blue-logo-2;

                        &:before,
                        &:after {
                            background: $color-blue-logo-2;
                        }
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: -1px;
                        left: -15px;
                        width: 30px;
                        height: 38px;
                        background: $color-blue-bright;
                        transform: skewX(-30deg);
                        @include border-radius($border-radius-3px, 0, 0, 0);
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: -1px;
                        right: -15px;
                        width: 30px;
                        height: 38px;
                        background: $color-blue-bright;
                        transform: skewX(30deg);
                        @include border-radius(0, $border-radius-3px, 0, 0);
                    }
                }

                &:first-child {
                    padding-left: 0 !important;

                    .btn {
                        margin-left: 0;
                        @include border-radius($border-radius-3px, 0, 0, 0);

                        &:before {
                            content: none;
                        }
                    }
                }

                &:last-child {
                    padding-right: 0 !important;

                    .btn {
                        margin-right: 0;
                        @include border-radius(0, $border-radius-3px, 0, 0);

                        &:after {
                            content: none;
                        }
                    }
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 5px;
                background: $color-blue-logo-2;
            }
        }

        .divisions-wrapper {
            position: relative;
            z-index: 1;
            font-size: 0;
            overflow: hidden;
            white-space: nowrap;
            background: $color-grey-white;
            @include transition(height, 0.3s, ease-out, 0s);

            .division-wrapper {
                display: inline-block;
                width: 100%;
                padding: 20px 15px 15px 15px;
                white-space: normal;
                border: 1px solid $color-blue-bright;
                border-top: none;
                @include opacity(0);
                @include border-radius(0, 0, $border-radius-3px, $border-radius-3px);
                @include transition(all, 0.3s, $ease-in-out-cubic, 0s);

                &.active {
                    @include opacity(1);
                }
            }
        }

        .division-controls {

            .mixin-btn {
                float: left;
                margin-top: 0 !important;

                .btn {
                    @include user-select(none);
                }
            }
        }
    }

    .select2.select2-container,
    .select2.select2-container--open {

        .select2-selection.select2-selection--default,
        .select2-selection.select2-selection--single,
        .select2-selection.select2-selection--multiple,
        .select2-selection.select2-selection--default:focus,
        .select2-selection.select2-selection--single:focus,
        .select2-selection.select2-selection--multiple:focus {
            border-color: $color-blue-bright;

            .select2-selection__rendered {
                width: 100%;
                min-height: 34px;
                color: $color-grey-dark;

                .select2-selection__choice {
                    display: inline-block;
                    line-height: 24px;
                    color: $color-blue-grey;
                    background: $color-grey-light;
                    border-color: $color-grey-bright;

                    &.ui-sortable-handle {

                        &:after {
                            position: relative;
                            top: 1px;
                            font-size: 17px;
                            line-height: 18px;
                        }
                    }
                }

                .select2-selection__placeholder {
                    color: $color-blue-bright;
                    font-size: $font-size-16px;
                    line-height: 28px;
                }

                .select2-search--inline {

                    &:first-of-type {
                        width: 100%;

                        input {
                            width: 100% !important;
                        }
                    }
                }
            }

            .select2-selection__arrow {
                background: $color-grey-white;
                border-color: $color-blue-bright;
                @include border-radius(0, $border-radius-3px, $border-radius-3px, 0);

                b {
                    border-top-color: $color-blue-bright;
                }
            }

            .select2-selection__clear {
                position: relative;
                color: $color-blue-bright;
                font-size: $font-size-18px;
                line-height: 29px;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 40px;
                    height: 60px;
                    @include transform(translate(0, -50%));

                }
            }

            .select2-search__field {
                position: relative;
                top: -1px;
                font-size: $font-size-16px;
                @include placeholder($color-blue-bright);
            }
        }

        &.error-flag {
            .select2-selection.select2-selection--default,
            .select2-selection.select2-selection--single,
            .select2-selection.select2-selection--multiple,
            .select2-selection.select2-selection--default:focus,
            .select2-selection.select2-selection--single:focus,
            .select2-selection.select2-selection--multiple:focus {
                border-color: $color-red-error !important;
            }
        }
    }
}

.select2-container {

    &.select2-container--classic,
    &.select2-container--open {

        .select2-dropdown {
            border-color: $color-blue-bright;

            .select2-search__field {
                border-color: $color-blue-bright;
            }

            .select2-results__option {
                color: $color-blue-bright;

                .select2-results__group {
                    font-size: $font-size-16px;
                    font-weight: 500;
                }

                &[role=group] {
                    border-top: 1px solid $color-blue-logo-2-opacity-005p;
                }

                &[aria-selected=true] {
                    color: $color-grey-white;

                    &:before {
                        background: $color-blue-logo-2;
                    }
                }

                &.select2-results__option--highlighted {
                    color: $color-grey-white;

                    &:before {
                        background: $color-blue-dark;
                    }
                }
            }

            &.select2-dropdown--above {
                border-color: $color-blue-bright;
            }
        }
    }
}

@mixin max-width-division-form($clm-x) {

    &.division-form {

        .division-header {

            .mixin-btn {

                &#{$clm-x} {
                    width: 100%;
                    margin-bottom: 2px !important;
                    padding: 0 !important;

                    .btn {
                        border: 1px solid $color-blue-bright;
                        border-bottom: none !important;
                        @include border-radius(0, 0, 0, 0);

                        &.active {
                            border: 1px solid $color-blue-logo-2;
                        }

                        &:before,
                        &:after {
                            content: none;
                        }
                    }

                    &:first-child {

                        .btn {
                            @include border-radius($border-radius-3px, $border-radius-3px, 0, 0);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {

    .clm-wrapper {

        .clm {

            &.clm-2 {

                form {
                    @include max-width-division-form('.clm-3');
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    form {
        @include max-width-division-form('.clm-5');

        .row {

            &.row-border {
                padding-bottom: 10px;
            }
        }
    }
}

@media screen and (max-width: 600px) {

    form {
        @include max-width-division-form('.clm-3');
    }
}

@media screen and (max-width: 500px) {

    form {
        @include max-width-division-form('.clm-2');

        .row {

            > a,
            .link-style {
                font-weight: 600;
            }
        }

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=password],
        input[type=date],
        input[type=time],
        input[type=datetime-local],
        textarea,
        .input-checkbox,
        .input-radio,
        .input-switcher,
        .type-text-style,
        .type-textarea-style {
            font-weight: 400;
        }

        .select2.select2-container,
        .select2.select2-container--open {

            .select2-selection.select2-selection--default,
            .select2-selection.select2-selection--single,
            .select2-selection.select2-selection--multiple,
            .select2-selection.select2-selection--default:focus,
            .select2-selection.select2-selection--single:focus,
            .select2-selection.select2-selection--multiple:focus {

                .select2-selection__rendered {
                    font-weight: 400;

                    .select2-selection__choice {

                        .select2-selection__choice__remove {
                            font-weight: 400;
                        }
                    }
                }

                .select2-selection__clear {
                    font-weight: 500;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {

    form {

        &.division-form {

            .divisions-wrapper {

                .division-wrapper {
                    padding: 10px;
                }
            }

            .division-controls {

                .mixin-btn {
                    float: none;
                }
            }
        }
    }
}
