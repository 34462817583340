@use '../../basics/globals' as *;

.mixin-slider-range {
    position: relative;
    margin: 20px 0;
    padding-top: 20px;
    border-top: 1px solid $color-blue-logo-2-opacity-010p;

    > .header {
        display: flex;

        .label {
            display: block;
            margin-bottom: 5px;
            font-size: $font-size-14px;
            font-weight: 600;
            text-align: center;
            @include flex(1 1 auto);
        }

        .control {
            position: relative;
            height: 20px;
            cursor: pointer;
            @include flex(0 0 20px);

            span {
                position: absolute;
                top: -10px;
                bottom: -10px;
                left: -10px;
                right: -10px;
            }

            &.plus {

                &:after {
                    content: '';
                    width: 1px;
                    height: 10px;
                }
            }

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;
                width: 10px;
                height: 1px;
                background: $color-grey-dark;
                @include transform(translate(-50%, -50%));
            }

            &:before {
                content: '';
            }
        }
    }

    .free-bar-wrapper {
        position: absolute;
        top: 71px;
        left: 0;
        width: 100%;
        height: 12px;
        overflow: hidden;
        pointer-events: none;
        @include border-radius(100px, 0, 0, 100px);

        .free-bar {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 0;
            background: $color-blue-logo-1;
            @include transition(width, 0.5s, $ease-in-out-cubic, 0s);
        }
    }

    .irs--flat {

        * {
            font-weight: 600;
            @include font-family('Museo Slab');
        }

        .irs-line {
            top: 25px;
            background: $color-blue-bright;
            @include border-radius(100px, 100px, 100px, 100px);
        }

        .irs-min,
        .irs-max {
            display: none;
        }

        .irs-single,
        .irs-from,
        .irs-to {
            z-index: 1;
            padding: 3px 5px;
            color: $color-grey-white;
            font-size: $font-size-12px;
            line-height: 1em;

            &.irs-from {
                background: $color-blue-logo-1;
            }

            &.irs-single,
            &.irs-to {
                background: $color-blue-logo-2;
            }

            &:before {
                content: none;
            }
        }

        .irs-grid {

            .irs-grid-pol {
                background: $color-blue-bright;
            }

            .irs-grid-text {
                color: $color-blue-bright;
            }
        }

        .irs-bar {
            top: 25px;
            background: $color-blue-logo-2;
            @include border-radius(100px, 0, 0, 100px);
        }

        .irs-handle,
        .irs-handle:hover {
            top: 22px;

            &.from {

                > i {

                    &:first-child {
                        background: $color-blue-logo-1;
                    }
                }
            }

            &.single,
            &.to {
                z-index: 2;

                > i {

                    &:first-child {
                        background: $color-blue-logo-2;
                    }
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 40px;
                height: 60px;
                @include transform(translate(-50%, -50%));
            }
        }

        &:after {
            content: '↔';
            position: absolute;
            top: -1px;
            left: 50%;
            color: $color-blue-bright;
            font-size: $font-size-20px;
            @include transform(translate(-50%, 0));
            @include opacity(0.5);
        }
    }

    &.double {

        .irs--flat {

            .irs-from,
            .irs-to {
                visibility: visible !important;
            }

            .irs-single {
                display: none !important;
            }

            .irs-bar {
                @include border-radius(0, 0, 0, 0);
            }
        }
    }
}
