@use '../../basics/globals' as *;

.mixin-image-strip {
    display: flex;
    position: relative;
    z-index: 1;
    margin: -60px 0 20px 0;
    overflow: hidden;
    transform: skewY(-7deg);
    @include flex(1 1 auto);

    .img-wrapper {
        height: 300px;
        margin: -50px -1px -50px 0;
        background-size: cover;
        background-position: center;
        transform: skewY(7deg);
        @include flex(1 1 auto);
    }
}

@media screen and (max-width: 1000px) {

    .mixin-image-strip {

        .img-wrapper {
            display: none;

            &:nth-child(1),
            &:nth-child(2) {
                display: block;
            }
        }
    }
}
