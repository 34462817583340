@use '../../basics/globals' as *;

.mixin-preview {

    &.preview-configurator {

        .divisions-wrapper {

            .wrapper-logo {

                .row {

                    .input-file {
                        display: block;

                        .btn-wrapper {
                            @include gap(20px 10px);

                            .accept,
                            .dimension {
                                margin: 0;
                                padding: 5px;
                                font-size: $font-size-12px;
                                line-height: 1em;
                                text-align: center;
                                @include flex(1 0 auto);
                            }

                            .btn {
                                padding: 10px 15px;
                                color: $color-grey-white;
                                font-size: $font-size-16px;
                                line-height: 1em;
                                background: $color-blue-logo-2;
                                border: 1px solid $color-blue-logo-2;
                                cursor: pointer;
                                text-align: center;
                                text-transform: uppercase;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                @include flex(1 1 100%);
                                @include font-family('Exo 2');
                                @include border-radius(100px, 100px, 100px, 100px);
                            }

                            .info {
                                display: none;
                                margin: 0;
                                padding: 0;
                                font-size: $font-size-16px;
                                line-height: 1em;
                                text-align: center;
                                @include flex(0 0 100%);

                                &.error {
                                    display: block;
                                    color: $color-red-error;
                                    border-color: $transparent;
                                }
                            }
                        }

                        .img-wrapper {
                            clear: both;
                            padding: 20px 0 0 0;

                            img {
                                width: 100%;
                                height: auto;
                                max-width: none;
                                max-height: none;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .wrapper-colors {

                .row {
                    display: flex;
                    margin-bottom: -20px;
                    @include flex-wrap(wrap);
                    @include gap(10px 20px);

                    .wrapper-color {
                        display: inline-block;
                        position: relative;
                        @include flex(1 0 150px);

                        .color-picker-input {

                            .icon {
                                margin-right: 40px;
                                color: $color-blue-bright;
                                cursor: pointer;
                            }
                        }

                        .color-picker-background {
                            position: absolute;
                            z-index: 1;
                            bottom: 0;
                            right: 0;
                            width: 34px;
                            height: 34px;
                            border: 1px solid $color-blue-bright;
                            cursor: pointer;
                            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                        }

                        &.placeholder {
                            height: 1px;
                            border: none;
                            pointer-events: none;
                        }
                    }
                }
            }

            .wrapper-fonts {
                position: relative;

                .row {

                    .font-selector-input {

                        .icon {
                            margin-right: 46px;
                            color: $color-blue-bright;
                            cursor: pointer;
                        }
                    }

                    .edit {
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        right: 0;

                        .btn {
                            height: 34px;
                            padding: 8px 10px;
                            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &.preview-screen {
        position: relative;
        min-width: 500px;

        .smartphone {
            position: relative;
            width: 435px;
            height: 800px;
            margin: -70px 0 -70px -40px;
            padding: 60px 30px;
            background: $color-blue-dark;
            @include border-radius(50px, 50px, 50px, 50px);
            // @include transform(scale(0.8) rotate3d(0.342, -0.94, 0, 22deg) rotateZ(2deg));
            @include transform(scale(0.8));

            iframe {
                width: 100%;
                height: 100%;
                border: none;
                overflow-y: hidden;
            }

            &:before {
                content: '';
                position: absolute;
                top: 140px;
                left: -4px;
                width: 5px;
                height: 90px;
                background: $color-blue-dark;
                @include border-radius(3px, 0, 0, 3px);
            }

            &:after {
                content: '';
                position: absolute;
                top: 160px;
                right: -4px;
                width: 5px;
                height: 60px;
                background: $color-blue-dark;
                @include border-radius(0, 3px, 3px, 0);
            }
        }

        .mixin-qr-code {
            position: absolute;
            top: 40%;
            right: 0;
        }
    }
}

@media screen and (max-width: 1400px) {

    .mixin-preview {

        &.preview-screen {
            min-width: auto;

            .smartphone {
                margin-left: auto;
                margin-right: auto;
            }

            .mixin-qr-code {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-preview {

        &.preview-screen {

            .smartphone {
                display: none;
            }
        }
    }
}
