@use '../../basics/globals' as *;

#contracts-overlay-send-promotion {
    width: 400px;

    form {
        margin-top: 20px;
    }

    .wrapper-invitations {
        margin-top: 30px;
    }
}
