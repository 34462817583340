@use '../basics/globals' as *;

[data-vue] {
    @include opacity(0);
    @include transition(opacity, 0.3s, ease-out, 0.2s);

    &[data-v-app] {
        @include opacity(1);
    }
}
