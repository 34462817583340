@use '../basics/globals' as *;

#page-interfaces-interface-id {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    flex-direction: column;
    align-content: stretch;
    @include flex-wrap(wrap);

    * {
        font-weight: 500;
    }

    p {
        font-size: $font-size-16px;

        &.small {
            font-size: $font-size-14px !important;
        }
    }

    form {

        .row {
            font-size: 0;

            label {
                font-size: $font-size-14px;
            }

            > a,
            > .link-style {
                font-size: $font-size-12px;
            }
        }

        .input-checkbox,
        .input-radio {

            label {

                span {
                    font-size: $font-size-14px;
                }
            }
        }

        .type-text-style {

            .icon {
                top: 5px;
                font-size: 16px;
            }
        }
    }

    table {

        * {
            font-size: 14px;
        }

        tr {

            th,
            td {

                > .icon {

                    &.icon-x,
                    &.icon-pending,
                    &.icon-warning {
                        top: 4px;
                    }
                }

                .mixin-btn {

                    .btn {
                        height: 22px;

                        .icon {
                            top: 1px;
                        }
                    }
                }

                &.mixin-table-th {

                    .headline {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .section-content {

        &:first-child {
            background-image: none;
        }
    }

    .tile-wrapper {
        margin: 0;
        padding: 15px;
        @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));
    }

    .clm-wrapper {

        &.key-value {

            .icon-wrapper,
            .icon {

                .icon {
                    font-size: 18px;
                }
            }
        }
    }

    .mixin-btn {
        margin-top: 0 !important;

        .btn {
            padding: 8px 12px;
            height: 34px;
        }
    }

    .mixin-form-label {

        .text {
            font-weight: 700;
        }
    }

    #main {
        display: flex;
        overflow: hidden;
        @include flex(1 1 0);
    }

    #notify {
        background: $color-red-error;
    }

    #overlay {

        table {

            tr {

                td {
                    .mixin-btn {

                        .btn {
                            padding: 3px;
                            @include flex(0 0);
                        }
                    }
                }
            }
        }
    }

    [data-dashboard-id] {
        cursor: pointer;
    }

    &:before {
        content: none;
    }
}
