@use '../../basics/globals' as *;

.mixin-flow-chart {
    position: relative;
    border-bottom: 10px solid $color-green-middle;

    .mixin-article {
        position: relative;
        z-index: 1;

        .steps {
            display: flex;
            margin-top: 20px;
            @include flex-wrap(wrap);
            @include gap(40px);

            .step {
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: center;
                @include flex(1 1 calc(25% - 120px));

                img {
                    max-width: 250px;
                }

                .equal-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    > * {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            top: -10px;
                            bottom: -10px;
                            left: -10px;
                            right: -10px;
                            z-index: -1;
                            background: $color-grey-white;
                            @include blur(10px);
                        }
                    }

                    p {
                        max-width: 250px;
                        text-align: center;
                    }

                    .mixin-btn {
                        width: 100%;
                        max-width: 250px;

                        .btn {
                            width: 100%;
                        }
                    }

                    .mixin-link {
                        text-align: center;
                    }
                }
            }

            .transition {
                display: flex;
                justify-content: center;
                position: relative;
                @include flex(0 0 50px);

                img {
                    width: 50px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    width: 48px;
                    height: 48px;
                    background: $color-grey-white;
                    @include transform(translate(-50%, -50%));
                    @include border-radius(50%, 50%, 50%, 50%);
                }
            }
        }
    }

    .backround-image-wrapper {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center 100px;
        background-size: cover;
        @include opacity(0.2);
    }
}

@media screen and (max-width: 1400px) {

    .mixin-flow-chart {

        .mixin-article {

            .steps {

                .step {
                    @include flex(1 1 calc(50% - 65px));
                }

                .transition {

                    &:nth-child(4),
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    .mixin-flow-chart {

        .mixin-article {

            .steps {

                .step {
                    @include flex(1 1 100%);

                    .equal-wrapper {
                        content: 'data-set-equal-min-height';
                    }
                }

                .transition {
                    display: none;
                }
            }
        }
    }
}
