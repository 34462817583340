@use '../../basics/globals' as *;

.mixin-tile-items {

    .clm-wrapper {

        .clm {
            max-width: 600px;

            .mixin-image-wrapper {
                position: relative;
                margin: -40px -30px 40px -30px;
                padding-top: calc(50% + 30px);
                overflow: hidden;

                img {
                    position: absolute;
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}
