@use '../../basics/globals' as *;

.mixin-image-wrapper {
    position: relative;
    height: 100%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;

        &.img-loaded {

            &.border-radius {
                @include border-radius($border-radius-30px, $border-radius-30px, $border-radius-30px, $border-radius-30px);
            }

            &.box-shadow {
                @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.2));
            }
        }

        &:not(.img-loaded) {
            width: 100%;
            height: 100%;
        }
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;

        &.motion-app-001 {
            left: calc(50%);
            max-height: none;
            @include opacity(0);

            &[style*='height:'] {
                @include transition(opacity, 0.3s, ease-out, 0s);
                @include opacity(1);
            }
        }
    }

    figcaption {
        position: absolute;
        top: 50%;
        left: 20px;
        right: 20px;
        color: $color-white;
        font-size: $font-size-30px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include user-select(none);
        @include font-family('Exo 2');
        @include transform(translate(0, -50%));
    }

    &.center {

        img {
            left: 50%;
            @include transform(translate(-50%, 0));
        }
    }

    &.bottom {

        img,
        video {
            top: auto;
            bottom: 0;
        }
    }

    &.right {

        img {
            left: auto !important;
            right: 0;
        }
    }

    &.deco {

        &:after {
            content: '';
            position: absolute;
            bottom: -100px;
            right: -200px;
            z-index: -1;
            width: 800px;
            height: 100px;
            background: $color-blue-logo-2-opacity-015p;
            transform: skewY(-7deg);
        }
    }
}

html.isSafari {

    .mixin-image-wrapper {

        video {

            &.motion-app-001 {
                @include border-radius(20px, 20px, 20px, 20px);
            }
        }
    }
}

@media screen and (min-width: 1601px) {

    .mixin-image-wrapper {

        &.oversize {

            img {
                left: -100px;
                max-width: calc(100% + 100px);
                max-height: calc(100% + 100px);
            }

            &.supersize {

                img {
                    top: -50px;
                    max-width: calc(100% + 200px);
                    max-height: calc(100% + 200px);
                }
            }

            &.right {

                img {
                    left: auto;
                    right: -100px;
                }
            }

            &.viewport-control {

                img {
                    left: -200px;
                }

                &.center {

                    img {
                        left: calc(50% - 100px);
                    }
                }

                &.right {

                    img {
                        right: -200px;
                    }
                }
            }

            &.is-visible-in-viewport {

                img {
                    left: -100px;
                }

                &.center {

                    img {
                        left: 50%;
                    }
                }

                &.right {

                    img {
                        right: -100px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {

    .mixin-image-wrapper {

        video {

            &.motion-app-001 {
                left: calc(43%);
            }
        }
    }

    html.isSafari {

        .mixin-image-wrapper {

            video {

                &.motion-app-001 {
                    @include border-radius(14px, 14px, 14px, 14px);
                }
            }
        }
    }
}

@media screen and (min-width: 1001px) {

    .mixin-image-wrapper {

        img {
            @include transition(all, $duration-500ms, ease-out, $duration-300ms);
        }

        &.viewport-control {

            img {
                left: -100px;
                @include opacity(0);
            }

            &.right {

                img {
                    right: -100px;
                }
            }
        }

        &.is-visible-in-viewport {

            img {
                left: 0;
                @include opacity(1);
            }

            &.right {

                img {
                    right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    .mixin-image-wrapper {

        img {
            display: block;
            position: relative;
            margin: 40px auto;
            width: 700px;

            &[data-overlay-url] {
                cursor: initial;
                pointer-events: none;
            }
        }

        video {

            &.motion-app-001 {
                left: calc(42%);
                bottom: 40px;
            }
        }
    }

    html.isSafari {

        .mixin-image-wrapper {

            video {

                &.motion-app-001 {
                    @include border-radius(25px, 25px, 25px, 25px);
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {

    html.isSafari {

        .mixin-image-wrapper {

            video {

                &.motion-app-001 {
                    @include border-radius(20px, 20px, 20px, 20px);
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {

    html.isSafari {

        .mixin-image-wrapper {

            video {

                &.motion-app-001 {
                    @include border-radius(15px, 15px, 15px, 15px);
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {

    html.isSafari {

        .mixin-image-wrapper {

            video {

                &.motion-app-001 {
                    @include border-radius(10px, 10px, 10px, 10px);
                }
            }
        }
    }
}
