@use '../../basics/globals' as *;

.mixin-form-quantity-selector {
    position: relative;
    text-align: center;

    .wrapper {
        display: inline-block;
        background: $color-grey-white;
        border: 1px solid $color-blue-bright;
        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

        .control {
            display: inline-block;
            position: relative;
            width: 30px;
            height: 30px;
            cursor: pointer;

            &.minus {
                border-right: 1px solid $color-blue-bright;
            }

            &.plus {
                border-left: 1px solid $color-blue-bright;

                &:after {
                    content: '';
                    width: 1px;
                    height: 10px;
                }
            }

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 2px;
                background: $color-blue-grey;
                @include transform(translate(-50%, -50%));
            }

            &:before {
                content: '';
            }
        }

        .value {
            display: inline-block;
            width: 30px;
            padding-top: 3px;
            color: $color-blue-grey;
            font-size: $font-size-16px;
            font-weight: 600;
            @include user-select(none);
        }
    }
}
