@use '../../basics/globals' as *;

#interface-dashboard-footer {
    display: flex;
    background: $color-grey-light;

    ul {
        padding: 5px 25px;
        font-size: 0;
        @include flex(0 0 auto);

        li {
            display: inline-block;
            padding-right: 25px;
            font-size: 0;

            a {
                display: block;
                font-size: $font-size-14px;
                font-weight: 500;
                line-height: 30px;
                text-decoration: underline;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    .logo {
        padding: 13px 25px 13px 0;
        font-size: 0;
        text-align: right;
        @include flex(1 1 auto);

        img {
            height: 14px;
        }
    }
}

@media screen and (max-width: 500px) {

    #interface-dashboard-footer {

        ul {
            display: none;
        }
    }
}
