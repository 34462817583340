@use '../basics/globals' as *;

.mixin-tooltip {
    display: block !important;
    position: fixed !important;
    z-index: 9999999 !important;
    top: 0;
    left: 0;
    width: auto !important;
    height: auto !important;
    text-align: left !important;
    text-indent: 0 !important;
    overflow: visible !important;
    pointer-events: none !important;

    @include opacity(0);
    @include user-select(none);
    @include transition(all, $duration-300ms, ease-out, 0s);

    &.setup {
        @include transition(all, 0s, ease-out, 0s);
    }

    &.show {
        pointer-events: auto !important;
        @include opacity(1);
        @include transition(all, $duration-300ms, ease-out, 0s);
    }

    .text {
        position: relative !important;
        display: block !important;
        z-index: 2 !important;
        margin: 0 !important;
        padding: 10px 15px !important;
        color: $color-white !important;
        font-size: $font-size-12px !important;
        font-weight: 400 !important;
        line-height: 16px !important;
        text-transform: none !important;
        white-space: nowrap !important;

        a,
        input[type=submit],
        .link-style {
            color: inherit !important;
            font: inherit !important;
            line-height: inherit !important;
            text-decoration: underline !important;
        }

        br {
            line-height: 1.2em;
        }

        form {
            color: inherit !important;
            font: inherit !important;
            line-height: inherit !important;
        }
    }

    .text-bg {
        display: block;
        border: 1px solid $color-white;
        @include opacity(0.7);
        @include box-shadow(5px, 5px, 10px, 0, rgba(0, 0, 0, 0.5));

        .arrow-mask {
            display: block;
            position: absolute;
            overflow: hidden;

            .arrow-position {
                position: absolute;

                .arrow {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    border: 1px solid $color-white;
                    background: #000;
                    @include rotate-z(0, 45deg, 0, 0);
                    @include box-shadow(5px, 5px, 10px, 0, rgba(0, 0, 0, 0.5));
                }
            }
        }
    }

    &.top {
        @include transform(translate(-50%, -100%));

        .text {
            top: -15px !important;
        }

        .text-bg {
            top: -15px;

            .arrow-mask {
                top: 100%;
                left: 0;
                width: 100%;
                height: 20px;

                .arrow-position {
                    top: 0;
                    left: 50%;

                    .arrow {
                        top: -18px;
                        left: 0;
                    }
                }
            }
        }
    }

    &.bottom {
        @include transform(translate(-50%, 0));

        .text {
            top: 15px !important;
        }

        .text-bg {
            top: 15px;

            .arrow-mask {
                bottom: 100%;
                left: 0;
                width: 100%;
                height: 20px;

                .arrow-position {
                    bottom: 0;
                    left: 50%;

                    .arrow {
                        bottom: -10px;
                        left: 0;
                    }
                }
            }
        }
    }

    &.left {
        @include transform(translate(0, -50%));

        .text {
            left: -15px !important;
        }

        .text-bg {
            left: -15px;

            .arrow-mask {
                top: 0;
                left: 100%;
                width: 20px;
                height: 100%;

                .arrow-position {
                    top: 50%;
                    left: 0;

                    .arrow {
                        top: -13px;
                        left: -3px;
                    }
                }
            }
        }
    }

    &.right {
        left: 140%;
        top: 50%;
        @include transform(translate(0, -50%));

        .text {
            left: 15px;
        }

        .text-bg {
            left: 15px;

            .arrow-mask {
                top: 0;
                right: 100%;
                width: 20px;
                height: 100%;

                .arrow-position {
                    top: 50%;
                    right: 0;

                    .arrow {
                        top: -13px;
                        right: -23px;
                    }
                }
            }
        }
    }

    &.shift-left {

        .text {
            left: -30% !important;
        }

        .text-bg {
            left: -30% !important;

            .arrow-mask .arrow-position {
                left: 80%;
            }
        }
    }

    &.shift-right {

        .text {
            left: 30% !important;
        }

        .text-bg {
            left: 30% !important;

            .arrow-mask .arrow-position {
                left: 20%;
            }
        }
    }

    &.wrap {

        .text {
            white-space: normal !important;
        }
    }

    &.uppercase {

        .text {
            text-transform: uppercase !important;
        }
    }

    &.center {

        .text {
            text-align: center !important;
        }
    }

    &.on-click-close {
        cursor: default;
    }
}
