@use '../../basics/globals' as *;

#interface-dashboard-settings {
    
    form {
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-blue-logo-2-opacity-005p;

        .flex-wrapper {
            display: flex;
            @include flex-wrap(wrap);
            @include gap(20px);

            .flex-item {
                overflow: hidden;
                @include flex(1 1 auto);

                .mixin-btn {
                    padding-top: 0;

                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #interface-dashboard-settings {

        form {

            .flex-wrapper {

                .flex-item {
                    @include flex-i(1 1 100%);
                }
            }
        }
    }
}
