@use '../../basics/globals' as *;

.mixin-color-picker {
    width: 300px;
    max-width: 100%;

    .picker_wrapper.layout_default {
        display: block;
        padding: 0;
        width: auto;
        text-align: left;
        background: $transparent;
        @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

        .picker_slider {
            margin: 0 0 20px 0;
            padding: 4px;
            @include border-radius(4px, 4px, 4px, 4px);
            @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

            .picker_selector {
                padding: 8px;
                color: inherit;
                overflow: hidden;
                background: $transparent;
                @include border-radius(50%, 50%, 50%, 50%);
                @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: currentColor;
                }
            }
        }

        .picker_sl {
            margin: 0 0 20px 0;
            width: 100%;
            height: 150px;
            outline: 1px solid $color-blue-bright;
            @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

            .picker_selector {
                padding: 8px;
                color: inherit;
                overflow: hidden;
                background: $transparent;
                @include border-radius(50%, 50%, 50%, 50%);
                @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: currentColor;
                }
            }

            &:before {
                content: none;
            }
        }

        .picker_editor {
            display: inline-block;
            margin: 0 0 0 1px;
            width: 120px;

            input {
                padding: $form-padding;
                width: 100%;
                height: auto;
                color: $color-grey-middle;
                font-family: inherit;
                font-size: $form-font-size;
                font-weight: $form-font-weight;
                line-height: $form-line-height;
                border: 1px solid $color-blue-bright;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            }
        }

        .picker_sample {
            display: inline-block;
            position: relative;
            top: -1px;
            left: -34px;
            margin: 0;
            width: 34px;
            height: 34px;
            border: 1px solid $color-blue-bright;
            @include box-shadow(0, 0, 0, 0, rgba(0, 0, 0, 0));
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
        }

        .picker_done {
            display: inline-block;
            float: right;
            margin: 0;

            button {
                padding: 5px 10px;
                color: $color-grey-white;
                font-weight: 300;
                font-size: $font-size-14px;
                line-height: 1.6em;
                text-align: center;
                text-transform: uppercase;
                white-space: nowrap;
                background: $color-blue-middle;
                border: 1px solid $transparent;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            }
        }
    }
}
