@use '../../basics/globals' as *;

#splasher {

    .wrapper-section-content {
        z-index: 2;
    }

    .image-wrapper {
        position: absolute;
        top: 0;
        bottom: -10px;
        right: 50%;
        width: 1240px;
        background-repeat: no-repeat;
        background-position: left bottom;
        pointer-events: none;
        @include transform(translate(23%, 0));
    }

    .background-wrapper {
        position: absolute;
        top: 0;
        bottom: -20px;
        right: -30px;
        width: 750px;
        background-repeat: no-repeat;
        background-position: right bottom;
        pointer-events: none;
    }
}

@media screen and (max-width: 1400px) {

    #splasher {

        .background-wrapper {
            display: none;
        }
    }
}

@media screen and (max-width: 1000px) {

    #splasher {

        .image-wrapper {
            display: none;
        }
    }
}
