@use '../../basics/globals' as *;

.notifyjs-corner {
    left: 50%;
    right: auto !important;
    z-index: 300000 !important;
    width: 100%;
    max-width: 600px;
    margin: 0 !important;
    padding: 7px 10px;
    text-align: center;
    @include transform(translate(-50%, 0));

    .notifyjs-wrapper {
        margin: 3px 0 !important;

        .notifyjs-container {
            margin: 0 !important;

            #notify {
                display: inline-block;
                padding: 10px 10px 7px 10px;
                background: $color-blue-dark;
                @include box-shadow(0, 0, 30px, 0, rgba(0, 0, 0, 0.2));
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                span {
                    color: $color-grey-white;
                    font-size: $font-size-16px;
                    hyphens: auto;

                    a {
                        color: inherit;
                        font: inherit;
                        text-decoration: underline;
                    }

                    &.icon {
                        top: 3px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
