@use '../../basics/globals' as *;

.mixin-image-manager {
    display: flex;
    justify-content: space-between;
    min-height: 220px;
    background: $color-blue-black;
    border: 1px solid $color-blue-black;
    @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

    .image {
        width: auto;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        @include flex(1 1 auto);
    }

    .no-image {
        @include flex(1 1 auto);

        span {
            display: block;
            position: relative;
            top: 50%;
            width: 100%;
            padding: 0 20px;
            color: $color-grey-white;
            font-size: $font-size-16px;
            text-align: center;
            @include user-select(none);
            @include transform(translate(0, -50%));
        }
    }

    .controls {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px 20px;
        @include flex(0 1 auto);

        .control {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            @include flex(1 1 auto);

            > a,
            > span {
                line-height: 1em;
                cursor: pointer;

                &:hover:not(.no-css-hover) {

                    .text {
                        text-decoration: underline;
                    }
                }

                &.disabled {
                    cursor: default;

                    &:hover:not(.no-css-hover) {

                        .text {
                            text-decoration: none;
                        }
                    }

                    .icon {
                        color: $color-blue-grey;
                        @include transition(color, 0.3s, ease-out, 0s);
                    }

                    .text {
                        color: $color-blue-grey;
                        @include transition(color, 0.3s, ease-out, 0s);
                    }
                }
            }

            .icon {
                top: -1px;
                color: $color-grey-white;
                height: 16px;
            }

            .text {
                display: inline-block;
                padding-left: 15px;
                color: $color-grey-white;
                font-size: $font-size-16px;
                line-height: 1;
            }
        }
    }
}

@media screen and (max-width: 500px) {

    .mixin-image-manager {

        .controls {

            .control {

                .text {
                    display: none;
                }

                &:nth-child(2) {
                    display: none;
                }
            }
        }
    }
}
