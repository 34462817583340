@use '../basics/globals' as *;

form {
    text-align: left;

    .row {
        position: relative;
        margin-bottom: 10px;
        font-size: 0;

        .form-info {
            position: absolute;
            top: calc(var(--top) + 8px);
            right: 10px;
            @include opacity(0);
            @include transition(opacity, $duration-300ms, ease-out, 0s);

            .icon {
                color: $color-red-error;
                font-size: 18px;
            }

            &.show {
                @include opacity(1);
            }
        }

        .counter-wrapper {
            position: absolute;
            top: 5px;
            right: 5px;
            color: $color-grey-middle;
            font-size: $font-size-12px;

            &.counter-overlength {
                color: $color-red-error;
            }
        }

        .validation-status-wrapper {

            span {
                display: block;
                color: $color-grey-middle;
                font-size: $font-size-12px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form-box {
        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
        @include transition(all, $duration-300ms, ease-out, 0s);

        &.error-flag {
            border-color: $color-red-error;
        }

        &.margin-top {
            margin-top: 20px;
        }
    }

    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    input[type=date],
    input[type=time],
    input[type=datetime-local],
    textarea,
    .input-checkbox,
    .input-radio,
    .input-switcher,
    .type-text-style,
    .type-textarea-style {
        display: block;
        width: 100%;
        padding: $form-padding;
        color: $color-grey-middle;
        font-size: $form-font-size;
        font-weight: $form-font-weight;
        line-height: $form-line-height;
        border: 1px solid $color-grey-middle;
        background: $color-white;
        @include placeholder($color-grey-bright);

        &:-webkit-autofill {
            @include text-fill-color($color-grey-middle);
            @include box-shadow-inset(0, 0, 0, 1000px, $color-white);
            @include transition-i(all, 0s, ease-out, 0s);
        }

        &.unchangeable {
            color: $color-grey-dim;
            border-color: $transparent;
        }

        &.input-checkbox,
        &.input-radio {

            label {
                display: block;
                line-height: inherit;
                hyphens: auto;
                @include user-select(none);

                span {
                    color: inherit;
                    font-size: inherit;
                    line-height: inherit;

                    a {
                        position: relative;
                        color: inherit;
                        font-size: inherit;
                        line-height: inherit;
                        text-decoration: underline;
                    }
                }
            }

            a:hover:hover:not(.no-css-hover) {
                text-decoration: underline;
            }
        }

        &.input-switcher {

            .mixin-switcher {
                float: right;
            }

            > span {
                font-size: inherit;
                line-height: inherit;
            }
        }

        &.type-text-style,
        &.type-textarea-style {
            display: flex;
            position: relative;
            border-color: $color-grey-middle;
            background: $color-grey-light;
            overflow: hidden;
            justify-content: space-between;
            @include gap(10px);

            .text {
                color: $color-grey-middle;
                font: inherit;
                line-hegith: inherit;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-decoration: inherit;
                @include flex(1 1);
            }

            .icon {
                top: 0;
                @include flex(0 0);
            }

            &.type-textarea-style {

                .text {
                    white-space: normal;
                }
            }
        }

        &:focus {
            border-color: $color-blue-middle;
            @include box-shadow-inset(0, 0, 3px, 0, $color-blue-middle);
        }

        input[type=password] {

            &:after {
                content: '###';
            }
        }
    }

    input[type=date],
    input[type=time],
    input[type=datetime-local] {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    textarea {
        height: 150px;
        @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
    }

    select {
        display: none;
        @include opacity(0);

        option {
            display: none;
            width: 0;
            height: 0;
            visibility: hidden;
            @include opacity(0);
        }

        &[name=language] {
            min-width: 200px;
        }

        &.tags {
            display: block;
        }

        &.mobile-native {
            display: block !important;
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            z-index: 9 !important;
            width: 100% !important;
            height: 100% !important;
            opacity: 1 !important;
            clip: auto !important;
            margin: 0 !important;
            color: $transparent !important;

            option {
                display: block;
                width: 100%;
                height: auto;
                visibility: visible;
                @include opacity(1);
            }
        }
    }

    code {
        padding: 4px 10px !important;
        background: $color-white !important;
        overflow-x: auto !important;
    }

    a {

        &.type-text-style {
            text-decoration: underline;
        }
    }

    .input-file {

        .btn-wrapper {
            display: flex;
            position: relative;
            @include flex-wrap(wrap);
            @include gap(10px 10px);

            input[type=file] {
                display: none;
            }

            .accept,
            .dimension,
            .info {
                display: inline-block;
                padding: 0 10px;
                line-height: 32px;
                color: $color-grey-middle;
                border: 1px solid $color-grey-middle;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);

                span {
                    font-size: inherit;
                    line-height: inherit;
                    color: inherit;
                }
            }
        }

        .img-wrapper {
            margin-top: 10px;
            font-size: 0;

            img {
                max-width: 200px;
                max-height: 100px;
                @include bg-pattern-chess($color-grey-light, $transparent, 10px);
            }

            img[src] {
                margin-bottom: 10px;
            }
        }
    }

    .select2.select2-container,
    .select2.select2-container--open {
        display: block;
        width: 100% !important;
        max-width: 100% !important;

        .select2-selection.select2-selection--default,
        .select2-selection.select2-selection--single,
        .select2-selection.select2-selection--multiple,
        .select2-selection.select2-selection--default:focus,
        .select2-selection.select2-selection--single:focus,
        .select2-selection.select2-selection--multiple:focus {
            min-height: 34px;
            border: 1px solid $color-grey-middle;
            background: $color-white;
            text-align: left;
            @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            @include transition(border-color, $duration-300ms, ease-out, 0s);

            .select2-selection__rendered {
                padding: $form-padding;
                padding-top: 2px;
                padding-bottom: 2px;
                color: $color-grey-middle;
                font-size: $form-font-size;
                font-weight: $form-font-weight;
                text-align: left;

                .select2-selection__choice {
                    max-width: 100%;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    color: $color-grey-middle;
                    font-size: $font-size-14px;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    a {
                        color: inherit;
                        font: inherit;
                        line-height: inherit;
                        text-decoration: underline;
                    }

                    .select2-selection__choice__remove {
                        position: relative;
                        top: 3px;
                        margin-left: 2px;
                        margin-right: 5px;
                        color: inherit;
                        font-weight: 300;
                        line-height: 1em;
                    }

                    &.ui-sortable-handle {
                        cursor: grab;

                        &:after {
                            content: '⠿';
                            margin-left: 5px;
                        }
                    }
                }

                .select2-selection__placeholder {
                    padding-right: 20px;
                    color: $color-grey-shiny;
                    line-height: 30px;
                }

                &.select2-not-removable {

                    .select2-selection__choice {

                        .select2-selection__choice__remove {
                            display: none;
                        }
                    }
                }
            }

            .select2-selection__arrow {
                height: 32px;
                border: none;
                border-left: 1px solid $color-grey-middle;
                background-image: -webkit-linear-gradient(top, $color-grey-light 50%, $color-grey-bright 100%);
                background-image: -o-linear-gradient(top, $color-grey-light 50%, $color-grey-bright 100%);
                background-image: linear-gradient(to bottom, $color-grey-light 50%, $color-grey-bright 100%);
                @include border-radius(0, 0, 0, 0);

                b {
                    border-color: $color-grey-middle $transparent $transparent;
                }
            }

            .select2-selection__clear {
                margin-left: 10px;
                margin-right: 0;
                color: $color-grey-middle;
                font-weight: 300;
            }

            .select2-search__field {
                width: auto !important;
                margin-top: 0;
                color: $color-grey-middle;
                @include placeholder($color-grey-shiny);
            }

            &.select2-selection.select2-selection--single,
            &.select2-selection.select2-selection--single:focus {

                .select2-selection__rendered {
                    padding-right: 25px;
                }
            }

            &.select2-selection.select2-selection--multiple,
            &.select2-selection.select2-selection--multiple:focus {

                .select2-selection__rendered {
                    display: flex;
                    @include flex-wrap(wrap);

                    .select2-selection__choice {
                        @include flex(0 0 auto);
                    }

                    .select2-search {
                        min-width: 50px;
                        @include flex(1 1 50px);

                        input {
                            width: 100% !important;
                        }
                    }
                }
            }
        }

        &.select2.select2-container--open {

            .select2-selection.select2-selection--default,
            .select2-selection.select2-selection--single,
            .select2-selection.select2-selection--multiple,
            .select2-selection.select2-selection--default:focus,
            .select2-selection.select2-selection--single:focus,
            .select2-selection.select2-selection--multiple:focus {

                .select2-selection__arrow {

                    b {
                        border-color: $transparent $transparent $color-grey-middle;
                    }
                }
            }
        }

        &.error-flag {
            .select2-selection.select2-selection--default,
            .select2-selection.select2-selection--single,
            .select2-selection.select2-selection--multiple,
            .select2-selection.select2-selection--default:focus,
            .select2-selection.select2-selection--single:focus,
            .select2-selection.select2-selection--multiple:focus {
                border-color: $color-red-error;
            }
        }
    }

    &.validate-wrapper {

        .error-message {
            color: $color-red-error;
            font-size: $font-size-14px;
            font-weight: 400;
            @include opacity(0);
        }

        &.error-flag {
            .error-message {
                @include opacity(1);
            }
        }
    }
}

.select2-container {

    &.select2-container--classic,
    &.select2-container--open {

        .select2-dropdown {
            padding: 0;
            border: 1px solid $color-grey-middle;
            border-top: none;
            background: $color-white;
            overflow: hidden;

            .select2-search__field {
                padding: $form-padding;
                color: $color-grey-middle;
                font-size: $form-font-size;
                font-weight: $form-font-weight;
                line-height: $form-line-height;
                border: 1px solid $color-grey-middle;
                background: $color-white;
                @include border-radius($border-radius-3px, $border-radius-3px, $border-radius-3px, $border-radius-3px);
            }

            .select2-results__options {
                overflow-x: hidden;
            }

            .select2-results__option {
                margin-bottom: 1px;
                padding: 3px 5px;
                color: $color-grey-dim;
                font-size: $form-font-size;
                font-weight: $form-font-weight;

                .select2-results__group {
                    padding-bottom: 0;
                    text-transform: uppercase;
                }

                &[role=group] {
                    border-top: 1px solid $color-grey-middle;

                    &:first-child {
                        border-top: none;
                    }
                }

                &[aria-selected=true] {
                    position: relative;
                    z-index: 1;
                    color: $color-white;

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        left: -5px;
                        right: -5px;
                        background: $color-grey-bright;
                    }
                }

                &.select2-results__option--highlighted {
                    position: relative;
                    z-index: 1;
                    color: $color-white;

                    &:before {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        bottom: 0;
                        left: -5px;
                        right: -5px;
                        background: $color-blue-middle;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.select2-dropdown--above {
                border-top: 1px solid $color-grey-middle;
                border-bottom: none;
            }

            &.select2-crop-text {

                .select2-results__option {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        &.select2-container--open {
            z-index: 9999999;

            &.select2-container--above {

                .select2-selection.select2-selection--default,
                .select2-selection.select2-selection--single,
                .select2-selection.select2-selection--multiple,
                .select2-selection.select2-selection--default:focus,
                .select2-selection.select2-selection--single:focus,
                .select2-selection.select2-selection--multiple:focus {
                    @include border-radius(0, 0, $border-radius-3px, $border-radius-3px);
                }
            }

            &.select2-container--below {

                .select2-selection.select2-selection--default,
                .select2-selection.select2-selection--single,
                .select2-selection.select2-selection--multiple,
                .select2-selection.select2-selection--default:focus,
                .select2-selection.select2-selection--single:focus,
                .select2-selection.select2-selection--multiple:focus {
                    @include border-radius($border-radius-3px, $border-radius-3px, 0, 0);
                }
            }
        }
    }
}
