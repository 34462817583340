@use '../../basics/globals' as *;

#pricing-overlay-supported-languages {
    width: 600px;

    .mixin-article {

        .mixin-article-list {

            &.clms-3 {

                > li {
                    @include flex-basis-i(calc(33.333333% - 20px));
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {

    #pricing-overlay-supported-languages {

        .mixin-article {

            .mixin-article-list {

                &.clms-3 {

                    > li {
                        @include flex-basis-i(calc(50% - 20px));
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {

    #pricing-overlay-supported-languages {

        .mixin-article {

            .mixin-article-list {

                &.clms-3 {

                    > li {
                        @include flex-basis-i(100%);
                    }
                }
            }
        }
    }
}
