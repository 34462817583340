@use '../../basics/globals' as *;

.mixin-form-identity-document {

    > .clm-wrapper {

        > .clm {

            &:first-child {
                position: relative;
            }
        }

        input[type=file] {
            display: none;
        }

        .form-box {

            &.type-text-style {

                .icon {
                    float: right;
                    top: 3px;
                    right: 7px;

                    &.icon-checkmark {
                        top: 5px;
                        color: $color-green-middle;
                    }
                }
            }
        }

        .mixin-btn {
            margin: 0 !important;
            padding-top: 0 !important;

            .btn {
                width: 100%;
                padding-top: 10px;
                padding-bottom: 8px;
            }
        }

        .mixin-form-field-info {
            right: 10px;
        }

        .file-info {
            margin-top: 10px;
            padding: 0 3px;

            .name {
                display: inline-block;
                max-width: 100%;
                font-size: $font-size-14px;
                line-break: anywhere;
                overflow: hidden;
            }

            .icon {
                float: right;
                padding: 2px 0 0 10px;
                cursor: pointer;
            }
        }

        .clm-wrapper {
            display: flex;
            @include gap(10px 20px);

            .clm {
                width: auto !important;
                padding: 0 !important;
                @include flex(1 1 calc(50% - 20px));
            }
        }
    }
}

@media screen and (max-width: 700px) {

    .mixin-form-identity-document {

        > .clm-wrapper {

            .clm-wrapper {

                .clm {
                    width: 100% !important;
                    margin-bottom: 0;
                    @include flex(1 1 100%);
                }
            }
        }
    }
}
