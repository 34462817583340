@use '../basics/globals' as *;

.helper-loading {
    display: none;
    background: rgba(255, 255, 255, 0.5);

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 200px;
        background-image: url('../images/assets/app/layout/loading.svg?#{$timestamp}');
        background-size: 100%;
        @include transform(translate(-50%, -50%));
    }
}
