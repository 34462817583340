@use '../../basics/globals' as *;

#slideup {
    position: relative;
    padding-top: 20px;

    .links {
        justify-content: center;

        .label {
            font-size: $font-size-16px;
            @include font-family('Exo 2');

            &.active,
            &:hover:not(.no-css-hover) {
                text-decoration: underline;
            }
        }
    }

    .contents {
        position: relative;

        .content {
            padding: 60px 0 20px 0;

            h4 {
                margin: 30px 0 10px 0;
                color: $color-blue-logo-2;
                font-size: $font-size-20px;
                font-weight: 500;
                line-height: 1.3em;
                @include font-family('Museo Slab');
            }

            h5 {
                margin: 20px 0 10px 0;
                color: $color-blue-logo-2;
                font-size: $font-size-14px;
                font-weight: 600;
                line-height: 1.3em;
                text-transform: uppercase;
            }

            p {
                margin-bottom: 10px;
                font-size: $font-size-14px;
            }

            a {
                color: $color-blue-logo-1;
                font-size: $font-size-14px;

                &:hover:not(.no-css-hover) {
                    text-decoration: underline;
                }
            }

            ul {
                margin-bottom: 10px;

                li {
                    position: relative;
                    padding-left: 20px;
                    font-size: $font-size-14px;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 9px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        background: $color-grey-dark;
                        @include border-radius(50%, 50%, 50%, 50%);
                    }
                }
            }
        }

        .close {
            top: 30px;
            padding: 10px 15px;
            color: $color-blue-logo-2;
            font-size: 16px;
            line-height: 1em;
            text-transform: uppercase;
            border: 1px solid $color-blue-logo-2;
            cursor: pointer;
            @include font-family('Exo 2');
            @include border-radius(100px, 100px, 100px, 100px);
        }
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100vw;
        width: 200vw;
        height: 1px;
        background: linear-gradient(90deg, $color-blue-logo-2-opacity-015p, $color-blue-logo-2-opacity-015p 50%, $transparent 0, $transparent);
        background-size: 8px 1px;
        pointer-events: none;
    }
}

@media screen and (max-width: 500px) {

    #slideup {

        .contents {

            .content {

                h4 {
                    font-weight: 600;
                }

                h5 {
                    font-weight: 700;
                }
            }

            .close {
                font-weight: 500;
            }
        }
    }
}
