@use '../../basics/globals' as *;

.mixin-link {
    margin-top: 20px;
    font-size: 0;
    @include user-select(none);

    .link {
        display: inline-block;
        margin-bottom: 5px;
        color: $color-blue-logo-1;
        font-weight: 500;
        text-decoration: underline;

        .icon {
            top: 9px;
            margin-left: 8px;
            color: inherit;
            font-size: 11px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.no-margin {
        margin: 0;
    }
}
