@use '../../basics/globals' as *;

#users-overlay-select-orders {
    width: 450px;

    form {
        margin-top: 20px;

        .row {

            &.orders {
                margin: 0 0 7px 0;
                padding-right: 30px;
                font-size: 0;

                .form-box {
                    display: inline-block;
                    margin: 0;
                    padding: 0;
                    width: 20px;
                    height: 20px;
                    border: none;
                }

                .date,
                .price {
                    font-size: $font-size-16px;
                    font-weight: 500;
                    line-height: 28px;
                    @include opacity(0.4);
                    @include transition(opacity, 0.3s, ease-out, 0s);

                    &.date {
                        margin-left: 15px;
                        font-variant: tabular-nums;
                    }

                    &.price {
                        float: right;
                        margin-right: 10px;
                    }

                    &:hover:not(.no-css-hover) {
                        text-decoration: underline;
                    }
                }

                .icon {
                    position: absolute;
                    top: 2px;
                    right: 0;
                }

                &.checked {

                    .date,
                    .price {
                        @include opacity(1);
                    }
                }
            }

            &.sum {
                margin-top: 5px;
                padding: 5px 40px 0 0;
                border-top: 1px solid $color-blue-bright;

                span {
                    display: inline-block;
                    font-size: $font-size-16px;
                    font-weight: 600;
                    line-height: 1.4em;

                    &.label {

                        &:first-letter {
                            text-transform: capitalize;
                        }
                    }

                    &.amount {
                        float: right;
                    }
                }
            }
        }
    }
}
