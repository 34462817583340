@use '../basics/globals' as *;

#page-maintenance {

    form {

        input[type=submit] {
            padding: 5px 15px;
            background: $color-blue-logo-2;
        }

        .mixin-toggle-password {

            .icon {
                color: $color-blue-logo-2;
            }
        }
    }

    .icon-excavator {
        color: $color-blue-logo-1;
    }
}
